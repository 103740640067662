import {IUserInfo} from "../types";
import {KeycloakInstance} from "keycloak-js";


export const headerFromToken = (token?: string) => {
    if (token) {
        return {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    } else {
        return {}
    }
};


export function getUserInfoFromToken(keycloak: KeycloakInstance) {
    const { tokenParsed } = keycloak;
    return  (
        {
            givenName: tokenParsed?.given_name,
            email: tokenParsed?.email,
            fiscalCode: tokenParsed?.ficalCode,
            familyName: tokenParsed?.family_name
        } as IUserInfo
    )
}



// Wrapper fetch che permette di controllare dapprima se il token è ancora valido, richiedendone il token aggiornato.
// Richiama la fetch presente nella callback.

const refreshToken = async (keycloak: Keycloak.KeycloakInstance) => {

    return new Promise<Keycloak.KeycloakInstance>((resolve, reject) => {

        keycloak.updateToken(5).then(function () {
            resolve(keycloak)
        }).catch(function () {
            reject(keycloak)
        });
    });
};


export const authFetch = async (kc: Keycloak.KeycloakInstance, callback: (kc: Keycloak.KeycloakInstance, args?: any)=>Promise<any>,
                         args?: any) => {

    try {
        const kcU: Keycloak.KeycloakInstance = await refreshToken(kc);
        return callback(kcU, args);
    } catch (e) {
        console.log("Error");
        kc.login();
    }
};
