import Moment from 'moment';
import {Map} from '../types/Map';
import get from 'lodash/get';
import {IStepContext, ParametersInterface} from "../types";
import {ACTIONS} from "../container/Wizard";



export interface IFieldError {
    error: boolean;
    errorString?: string;
}

export const defaultLocale = () => {
    return ((navigator.language === 'it-IT') ? 'it' : 'en');
};

export const hasError = (path: string, errors: Map<string>): boolean => {
    //return (errors[path] !== undefined || errors[path] !== null);
    return (errors[path] !== undefined);
};

export const isDate = (value: any): value is Moment.Moment => {
    return ( value as Moment.Moment).format !== undefined;
};

export const emptyField = (data: any, path: string): boolean => {
    const dataValue = get(data, path);
    return ((dataValue === null || dataValue === undefined))
};

export const empty = (data: any): boolean => {
    return (data === null || data === undefined || Object.keys(data).length ===0);
};

export const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const codiceFiscalePattern = /^(?:(?:[B-DF-HJ-NP-TV-Z]|[AEIOU])[AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[1256LMRS][\dLMNP-V])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i;
export const itaCAP = /^[0-9]{5}$/;
export const isNullOrUndefined = (value: any): boolean => {
    return (value === null || value === undefined)
};

export const isNumber = (value: string): boolean => ((value != null) && itaCAP.test(value));

export const filterSearch = (search: Map<string>): Map<string> => {
    let res: Map<string> = {};
    for (let key in search) {
        const value = search[key];
        if (value !== undefined && ((typeof value === 'string' &&  value.length > 0 ) || (typeof value === 'boolean') || (typeof value === 'number'))) {
            res[key]= search[key];
        }
    }
    //console.log('FilterSearch: ', res);
    return res;
};

// /** If the invalidIfStale option is set we suspend if resource has expired */
// function hasUsableData<
//     S extends Schema,
//     Params extends Readonly<object>,
//     >(
//     resource: RequestResource<ReadShape<S, Params>> | null,
//     fetchShape: ReadShape<S, Params>,
// ) {
//     return !(
//         (fetchShape.options && fetchShape.options.invalidIfStale) ||
//         !resource
//     );
// }
//
// /** Ensure a resource is available; loading and error returned explicitly. */
// export function useStatefulResource<
//     Params extends Readonly<object>,
//     S extends Schema
//     >(fetchShape: ReadShape<S, Params>, params: Params | null) {
//     let maybePromise = useRetrieve(fetchShape, params);
//     const resource = useCache(fetchShape, params);
//
//     const loading =
//         !hasUsableData(resource, fetchShape) &&
//         maybePromise &&
//         typeof maybePromise.then === 'function';
//
//     let error = useError(fetchShape, params, resource);
//
//     return {
//         data: resource,
//         loading,
//         error,
//     };
// }

export const fooT = (label: string) => label;

export const getStringValue = (paramValue: string | string[]) => {
    return (paramValue instanceof Array ? paramValue[0] : paramValue as string);
};



const kindConf: Map<any> = {

};


const actionConf: Map<ACTIONS> = {
  "new": ACTIONS.NEW,
  "edit": ACTIONS.EDIT,
  "cancel": ACTIONS.CANCEL
};

export const buildContext = (param: ParametersInterface): IStepContext<ACTIONS, any> => {
    const kind = kindConf[param.kind] as any;
    const action = actionConf[param.action] as ACTIONS;
    return ({
        kind: kind ,
        action: action
    })
};