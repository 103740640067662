import React from 'react'
import {Radio, Space} from 'antd'
import get from 'lodash/get'
import {Div, Flex, PaperLayout, VerticalLayout} from "@nic/ui-comps";
import AmministrazioniValidate from "../api/AmministrazioniValidate";
import {addressField, field} from "../utils/FieldUtils";
import {ValidateContext} from "../modules/form/context/ValidateContext";
import {PUBTEST_WARNING, SELECTED_PEC} from "../configuration";
import Utils from "../api/Utils";
import WarningInfo from "./WarningInfo";
import {useConfiguration} from "../utils";

interface OrgViewI {
    ammData: AmministrazioniValidate
    kind?: 'amministrazione' | 'uo'
    style?: React.CSSProperties
    viewUO: boolean
    selectedValue?: string
    selectPec?: boolean
    onSelectPec?: (pec: string) => void
}


function onlyValue(value: string) {
    return (
        <Flex
            direction={'column'}
            justify={'flex-start'}
            align={'flex-start'}
            style={{whiteSpace: 'nowrap', fontWeight: 700}}
        >
            {value}

        </Flex>
    )
}

/*
function simpleListView(label: string,list: string[]) {
    return (
        <Flex direction={'column'} justify={'flex-start'} align={'flex-start'}>
            <Div>{`${label}:`}</Div>
            {list.map((value: string, index: number) =>
                <Div key={index}
                     fontWeight={'700'}
                     style={{whiteSpace: 'nowrap'}}>{value}
                </Div>)
            }
        </Flex>
)
}
*/
/*
function simpleView(label: string, value: string) {
    return (
        <>
        <Div>{label}</Div>
        <Div fontWeight={'700'}>{value}</Div>
        </>
    )
}
*/
/*
function fullOrgView(ammData: AmministrazioniValidate, selectPec: boolean, selectHandler: (pec: string) => void)  {
    return (

        <HorizontalLayout style={{gap: 20}}>
            <FontAwesomeIcon size={'2x'} icon={faBuilding}/>
            <HorizontalLayout>
                <VerticalLayout>
                    {simpleView('Codice IPA', ammData.codiceIpa)}

                </VerticalLayout>
                <VerticalLayout>
                    {simpleView('Denominazione',ammData.denominazione)}
                </VerticalLayout>
                <VerticalLayout>
                    {simpleView('Codice Fiscale', ammData.codiceFiscale)}
                </VerticalLayout>
                <VerticalLayout>
                    {ammData.pecs.length > 1 && selectFieldValues('Pec:', ammData.pecs, selectHandler)}

                    {ammData.pecs.length === 1 && (
                        simpleListView('Pec', ammData.pecs))
                    }

                </VerticalLayout>

                <VerticalLayout>
                    <Div>Indirizzo</Div>
                    <Div fontWeight={'700'}>{ammData.address.indirizzo}</Div>
                    <Div fontWeight={'700'}>{ammData.address.cap} {ammData.address.comune} {ammData.address.provincia}</Div>
                </VerticalLayout>
            </HorizontalLayout>
        </HorizontalLayout>

    )
}
*/
/*
function fieldValue(label: string, value: string) {
    return (
    <Flex direction={'row'} justify={'flex-start'} style={{gap: 10}}>
        <Div>{`${label}:`}</Div>

        <Div fontWeight={'700'}>{value}</Div>
    </Flex>
    )

}
*/
/*
function fieldValues(label: string, values: string[]) {
    return (
        <Flex direction={'row'} justify={'flex-start'} style={{gap: 10}}>
            <Div>{`${label}:`}</Div>
            <VerticalLayout style={{alignItems: 'flex-start'}}>
                {values.map((value: string, i: number) =>
                    <Div key={i} fontWeight={'700'}>{value}</Div>
                )}

            </VerticalLayout>
        </Flex>
    )

}
*/

function selectFieldValues(label: string, values: string[], selectHandler: (pec: string) => void, selectedValue?: string, color?: string) {
    // const _color = (color?? '#e70000')
    return (
        <Flex direction={'column'} justify={'flex-start'} align={'flex-start'} style={{gap: 10}}>
            <Div>{`${label}:`}</Div>
            <Radio.Group onChange={({target}) => {selectHandler(target.value)}} value={selectedValue}>
                <Space direction="vertical" style={{alignItems: 'flex-start'}}>
                    {values.map((value: string, i: number) =>
                        <Radio key={i} value={value}>
                            <span
                                className="pec-item"
                            >
                                {value}
                            </span>
                        </Radio>
                    )}
                </Space>
            </Radio.Group>
        </Flex>
    )

}


/*
function fullUOView(ammData: AmministrazioniValidate, selectPec: boolean, selectHandler: (pec: string) => void)  {
    return (
        <VerticalLayout style={{gap: 10}}>
            {fieldValue('Codice Unità Organizzativa', ammData.codiceUO)}
            {fieldValue('Codice Fiscale', ammData.codiceFiscale)}
            {fieldValue('Codice IPA', ammData.codiceIpa)}
            {fieldValue('Denominazione', ammData.denominazione)}
            {!selectPec && fieldValues('Pec', ammData.pecs)}
            {selectPec && selectFieldValues('Pec', ammData.pecs, selectHandler)}



                    <Flex direction={'row'} justify={'flex-start'} style={{gap: 10}}>

                    <Div>Indirizzo:</Div>
                    <Div fontWeight={'700'}>{ammData.address.indirizzo}</Div>
                    <Div fontWeight={'700'}>{ammData.address.cap} {ammData.address.comune} {ammData.address.provincia}</Div>
                    </Flex>
        </VerticalLayout>
    )
}

*/



function fullView(ammData: AmministrazioniValidate, selectPec: boolean, selectHandler: (pec: string) => void, selectedValue?: string)  {
    // const selectedPEC = selectedValue ?? ammData.pecs[0]

    return (


        <>

        <VerticalLayout
            style={{
                gap: 20,
            }}
        >
            {field('Codice IPA',ammData.codiceIpa)}
            {ammData.codiceUO && field('Codice Unità Organizzativa', ammData.codiceUO)}
            {ammData.codiceFiscale && field('Codice Fiscale', ammData.codiceFiscale)}
            {field('Denominazione', ammData.denominazione)}


            {addressField('Indirizzo', ammData.address.indirizzo, ammData.address.cap, ammData.address.comune, ammData.address.provincia)}

        </VerticalLayout>
        </>

    )
}

/*
function minOrgView(ammData: AmministrazioniValidate) {
    return (
        <HorizontalLayout style={{justifyContent: 'flex-start', gap: 20}}>
            <FontAwesomeIcon size={'1x'} icon={faBuilding}/>
            <Space split={<Divider type="vertical" />}>
                <Flex direction={'row'} style={{gap: 5}}>
                    <Div>Codice IPA:</Div>
                    <Div fontWeight={'700'}>{ammData.codiceIpa}</Div>
                </Flex>
                <Flex direction={'row'} style={{gap: 5}}>

                    <Div>Denominazione:</Div>
                    <Div  fontWeight={'700'}>{ammData.denominazione}</Div>
                </Flex>
            </Space>
        </HorizontalLayout>
    )
}
*/
//
const fooSelect = (pec: string) => {}


const selectPec = (pecs: string[], selectHandler: (pec: string) => void, style: React.CSSProperties, selectedValue?: string) => {
    const _color = style.color;
    return (
        <>
        {selectFieldValues('Seleziona una pec', pecs, selectHandler, selectedValue, _color)}
        </>
    )
}



const OrgVew: React.FC<OrgViewI> = (props) => {
    const {ammData, selectedValue} = props
    //const kind = props.kind ?? 'amministrazione'
    //const uoView = viewUO ?? false
    const selectPecs = ammData.pecs.length > 1
    const pecHandòer = props.onSelectPec ?? fooSelect
    const selectHandler = selectPecs ? pecHandòer : fooSelect

    let component = fullView(ammData, selectPecs, selectHandler, selectedValue)

    const pecStyle = (selectedValue ? {
        border: '1px solid',
        borderColor: '#b7eb8f',
        background: '#f6ffed',
        color:'#389e0d',
        padding: '20px'
    } : {
        border: '1px solid',
        borderColor: 'rgb(255, 163, 158)',
        color: 'rgb(207, 19, 34)',
        background: 'rgb(255, 241, 240)',
        padding: '20px'
    })

    const configuration = useConfiguration();
    const validation = React.useContext(ValidateContext)

    const empty: boolean  = get(validation.errors, SELECTED_PEC) === undefined
    //console.log('Empty: ', empty, selectPecs, selectedValue)
    const classname = (!empty && selectPecs && !selectedValue? 'error-box': 'info-box')

    return (
        <VerticalLayout>

            <WarningInfo msg={PUBTEST_WARNING} rendered={Utils.isPubTest(configuration)} />


            <PaperLayout
                className={'info-box'}
                padding={'10px'}
                asCard={false} simple={false} >
                {component}
            </PaperLayout>


            {!selectPecs && (
                <VerticalLayout>
                    <Flex direction={'column'} align={'flex-start'} justify={'flex-start'} style={{marginTop:'20px'}}>
                        <Div>Indirizzo PEC al quale saranno inviati il codice di validazione e i dati necessari alla registrazione dei domini gov.it</Div>

                    </Flex>

                    <PaperLayout
                        className={'info-box'}
                    >
                        {onlyValue(ammData.pecs[0])}
                    </PaperLayout>
                </VerticalLayout>
            )
            }



            {selectPecs &&
            <VerticalLayout>
                <Flex direction={'row'} justify={'flex-start'} style={{marginTop:'20px'}}>
                    <Div>Indirizzo PEC al quale saranno inviati il codice di validazione e i dati necessari alla registrazione dei domini gov.it</Div>

                </Flex>
                <PaperLayout
                    className={classname}
                >

                    {selectPec(ammData.pecs, selectHandler, pecStyle, selectedValue)}
                </PaperLayout>
            </VerticalLayout>
            }
        </VerticalLayout>
    )
}
export default OrgVew
