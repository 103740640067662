import React from 'react';
import {faWarning} from "@fortawesome/free-solid-svg-icons";
import {Div, Flex, PaperLayout} from "@nic/ui-comps";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface WarningInfoI {
    rendered?: boolean;
    asCard?: boolean;
    msg: string
}
const WarningInfo: React.FC<WarningInfoI> = (props) => {
    const {msg, rendered} = props;
    const toRender = rendered ?? true;
    const asCard = props.asCard ?? false;
    const comp = (
        toRender ?
            (
                <PaperLayout asCard={asCard}>
                    <Flex direction={'row'} justify={'flex-start'} style={{gap: 20}}>
                        <FontAwesomeIcon size={'2x'} color={'red'} icon={faWarning}/>
                        <Div style={{textAlign: 'justify'}} fontSize={'1.1rem'}>{msg}</Div>
                    </Flex>
                </PaperLayout>
            ) :
            <></>
    );

    return (
        <>{comp}</>
    )
};

export default WarningInfo;