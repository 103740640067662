import React from 'react';

import get from "lodash/get";
import {Checkbox, DatePicker, Form as AntForm, Input, Input as AntInput, InputNumber, Radio, Select} from "antd";
import {ValidateContext} from "../../context/ValidateContext";
// Nota al momento si gestisce solamente la traduzione italiana
//import 'moment/locale/en-gb';
//import {ModeOption} from "antd/lib/select";
import {isHoliday, isInBusinessDayRange} from "../../utils/holidays";
import {Moment} from 'moment';
import 'moment/locale/it';
import {fooT, hasError} from "../../utils/utils";
import it_IT from 'antd/lib/locale-provider/it_IT';
import {IStoreContext} from "../../../../context/FormContext";

const antdLang: Record<string, any> = {
    'it-IT': it_IT
};



export enum FormKind {
    PASSWORD,
    TEXT,
    TEXTAREA,
    NUMBER,
    DATE,
    SELECT,
    CHECKBOX,
    ARRAY
}

export enum TextTransformation {
    UPPERCASE,
    LOWERCASE,
    NOWHITESPACES
}

type TimeMode = "date" | "time" | "month" | "year" | "decade" | undefined;

interface FormBaseI {
    t?: (label: string) => string
    language?: string;
}

interface FormInterfaceBase extends FormBaseI {
    path: string;
    kind: FormKind;
    context: React.Context<IStoreContext>;
    required?:boolean;
    placeholder?:string;
    label?:string;
    status?:string;
    validator?: (value: any) => boolean;
    datePickerMode?: TimeMode;
    textTransformation?: TextTransformation;
    useTranslation?: boolean;
    error?: string;
    errorMessage?: string;
    className?: string;
    valueTransform?: (value: any) => any;
    showOnlyBusinessDays?: boolean;
    help?: string;
}

interface FormAreaInterface extends FormBaseI {
    maxLength?:number;
    rows?: number;
}

export const  SelectModeValues = ["single" , "multiple"];

interface ComplexValue {
    value: string;
    component: React.ReactNode;
}

interface FormSelectInterface extends FormBaseI {
    optionValues?: Array<string>;
    optionComplexValues?: Array<ComplexValue>;
    translationPrefix?: string;
    mode?: string;
    indexValues?:boolean;
    allowClear?: boolean;
    asRadioButtons?: boolean;
}

declare const ValidateStatuses: ["success", "warning", "error", "validating", undefined];

const textTransform = (value: string, transformation: TextTransformation): string => {
  let res = value;
  switch (transformation) {
      case TextTransformation.LOWERCASE:
          res = res.toLowerCase();
          break;
      case TextTransformation.UPPERCASE:
          res = res.toUpperCase();
          break;
      case TextTransformation.NOWHITESPACES:
          res = res.replace(/\s/g, '');
          break;
      default:
          break;
  }
  return res;
};

export const getDefaultValidateMessage = (validateStatus: string, props: FormInterfaceBase): string | undefined =>  {
    const errorMessage = props.errorMessage ?? '';

    switch(validateStatus) {
        case 'error':

            return props.errorMessage;
        case 'success':
            return '';
        case 'required':
            return 'Campo obbligatorio ' + errorMessage;
        default:
            return undefined
    }
};


interface ValidateHandlerInterface  {
    validateHandler: (path: string, error:boolean | undefined, status: any) => void
}

const ActualFormItem: React.FC<FormInterfaceBase & FormSelectInterface & FormAreaInterface & ValidateHandlerInterface> = (props) => {
    const t = props.t ?? fooT;

    const {language} = props;
    let realLanguage = 'itIT';

    if (language !== undefined) {
        realLanguage = language;
    }

    const store = React.useContext(props.context);


    // Similar to componentDidMount and componentDidUpdate:
    React.useEffect(() => {
        store.handleUpdate(props.path,get(store.getData(), props.path));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.required, props.path]);



    let component: React.ReactElement<any> = <React.Fragment/>;
        const storedValue = get(store.getData(), props.path);

        // se è stato scelto di non usare la localizzazione allora deve essere fornito i valori della label, della placeholder degli errori e del successo
        let realPlaceholder=
            (props.useTranslation !== undefined  && !props.useTranslation ? props.placeholder : (props.placeholder ? t(props.placeholder) : t(`${props.path}.placeholder`)));


        switch (props.kind) {

            case FormKind.PASSWORD:
                component = (
                    <Input.Password
                        placeholder={realPlaceholder}
                        value={storedValue}
                        visibilityToggle={true}
                        onChange={(ev: any) => {
                            const {value} = ev.target;
                            let error = (!value && props.required) || (value && props.validator && !props.validator(value));
                            //props.validate(!error, props.path);
                            const status = (error ? 'error' : 'success');
                            props.validateHandler(props.path, error, status);
                            let dataValue = (props.textTransformation !== undefined ? textTransform(value, props.textTransformation) : value);
                            store.handleUpdate(props.path,dataValue);
                        }}
                    />
                );
                break;
            case FormKind.NUMBER:
                component= (
                    <InputNumber
                        value={storedValue}
                        onChange={
                            (value: any) => {
                                let error = (!value && props.required) || (value && props.validator && !props.validator(value));
                                //props.validate(!error, props.path);
                                const status = (error ? 'error' : 'success');
                                props.validateHandler(props.path, error, status);
                                store.handleUpdate(props.path,value);
                                //updater(value, !error, props.path);
                            }}
                    />
                );
                break;
            case FormKind.CHECKBOX:
                const boolvalue = (storedValue === 'true' || storedValue === true);
                component= (
                    <Checkbox
                        checked={boolvalue}
                        onChange={
                            (value: any) => {
                                let error = (!value && props.required) || (value && props.validator && !props.validator(value));
                                //props.validate(!error, props.path);
                                const status = (error ? 'error' : 'success');
                                //props.validateHandler(status);
                                props.validateHandler(props.path, error, status);
                                store.handleUpdate(props.path,value.target.checked);
                            }}
                    />
                );
                break;
            case FormKind.TEXTAREA:
                component = (
                    <Input.TextArea
                        value={storedValue}
                        rows={props.rows}
                        maxLength=
                            {props.maxLength}
                        onChange={(ev: any) => {
                            const {value} = ev.target;
                            let error = (!value && props.required) || (value && props.validator && !props.validator(value));
                            //props.validate(!error, props.path);
                            const status = (error ? 'error' : 'success');
                            //props.validateHandler(status);
                            props.validateHandler(props.path, error, status);
                            store.handleUpdate(props.path,value);
                        }}
                    />
                );
                break;
            case FormKind.DATE:
                let dateFormatter = 'DD-MMM-YYYY';
                if (props.useTranslation !== undefined && props.useTranslation) {
                    dateFormatter = t('date-formatter');
                }
                component = (
                    <DatePicker
                        placeholder={realPlaceholder}
                        locale={antdLang[`${realLanguage}${realLanguage.toUpperCase()}`]}
                        value={storedValue}
                        onChange={(value) => {
                            store.handleUpdate(props.path,value);
                        }}
                        style={{width: '100%'}}
                        format={dateFormatter}
                        disabledDate={(currentDate: Moment) => {
                            return props.showOnlyBusinessDays !== undefined && props.showOnlyBusinessDays && currentDate !== null && (!isInBusinessDayRange(currentDate) || isHoliday(currentDate))
                        }}
                    />
                );
                break;
            case FormKind.TEXT:
                component = (
                    <AntInput
                        placeholder={realPlaceholder}
                        value={storedValue}
                        maxLength={props.maxLength}
                        onChange={(ev: any) => {
                            const {value} = ev.target;
                            let dataValue = (props.textTransformation !== undefined ? textTransform(value, props.textTransformation) : value);
                            let error = (!dataValue && props.required) || (dataValue && props.validator && !props.validator(dataValue));
                            //props.validate(!error, props.path);
                            const status = (error ? 'error' : 'success');
                            props.validateHandler(props.path, error, status);

                            store.handleUpdate(props.path,dataValue);
                        }}
                    />

                );
                break;
            case FormKind.SELECT:

                let defaultValue: string = storedValue as string;

                let val = storedValue;

                if (props.asRadioButtons !== undefined && props.asRadioButtons) {


                    let values: Array<React.ReactNode> = [];

                    if (props.optionValues !== undefined) {

                        values = props.optionValues.map((v, i) => {

                            const value: string = `${v}`;

                            val = (props.useTranslation !== undefined && props.useTranslation ? t(`${props.translationPrefix}.${v}`) : v);

                            val = props.valueTransform !== undefined ? props.valueTransform(val) : val;

                            return (
                                <Radio.Button key={i} value={value}><span>{val}</span></Radio.Button>

                            )
                        });

                    } else {
                        if (props.optionComplexValues !== undefined) {
                            values = props.optionComplexValues.map((value, i) => {
                                val = (props.useTranslation !== undefined && props.useTranslation ? t(`${props.translationPrefix}.${value.value}`) : value);

                                if (val === defaultValue) {
                                    defaultValue = val;
                                }


                                return (
                                    <Radio.Button key={i} value={value.value}>
                                        <span style={{paddingRight: '10px'}}>{value.component}</span><span>{val}</span>
                                    </Radio.Button>
                                )
                            });
                        }
                    }
                    component = (
                        <Radio.Group
                            buttonStyle="solid"
                            value={defaultValue}
                            onChange={(e: any) => {

                                if (e !== undefined && e.target !== undefined && e.target.value !== undefined) {
                                    const value = e.target.value;

                                    let error = (!value && props.required) || (value && props.validator && !props.validator(value));

                                    const status = (error ? 'error' : 'success');
                                    props.validateHandler(props.path, error, status);
                                    store.handleUpdate(props.path,value);
                                }
                            }}
                        >
                            {values}
                        </Radio.Group>
                    );

                } else {

                    val = props.valueTransform !== undefined ? props.valueTransform(val) : val;

                    component = (
                        <Select
                            mode={props.mode as 'multiple' | 'tags'}
                            placeholder={realPlaceholder}
                            value={val}
                            allowClear={props.allowClear}
                            showSearch
                            style={{width: '100%'}}
                            onChange={(value: any) => {

                                let error = (!value && props.required) || (value && props.validator && !props.validator(value));

                                const status = (error ? 'error' : 'success');
                                //props.validateHandler(status);
                                props.validateHandler(props.path, error, status);
                                store.handleUpdate(props.path,value);
                            }}
                        >
                            {props.optionValues && props.optionValues.map((value, i) => {

                                let val = (props.useTranslation !== undefined && props.useTranslation ? t(`${props.translationPrefix}.${value}`) : value);

                                return (
                                    <Select.Option key={i}
                                                   value={props.indexValues !== undefined && props.indexValues ? i : value}>{val}</Select.Option>
                                )
                            })}
                        </Select>
                    );
                }
                break;
        }

    return (
        <>{component}</>
    )
};



export const FormItem: React.FC<FormInterfaceBase & FormSelectInterface & FormAreaInterface> = (props) => {
    const t = props.t ?? fooT;


    let _validationStatus: string | undefined;

    let store = React.useContext(props.context);

    const value = get(store.getData(), props.path);

    let validateContext = React.useContext(ValidateContext);

    const [validate, setValidate] = React.useState(undefined as (typeof ValidateStatuses)[number]);

    const [validationStatusValue, setValidationStatusValue] = React.useState(undefined as string | undefined);


    let errorValidateContext: boolean = (validateContext.errors[props.path] !== undefined);

    let _validateStatus = validate !== undefined ? validate : (errorValidateContext ? 'error' : undefined);

    let helpString = ((validate === undefined  && !errorValidateContext)? undefined: `${props.path}.${_validateStatus}`);

    helpString =  (helpString ? helpString : (props.status ? `${props.path}.${props.status}`: undefined));

    let _help18n:string | undefined = _validateStatus !== undefined ?  getDefaultValidateMessage(_validateStatus as string, props) : undefined;

    if (props.useTranslation && helpString !== undefined) {
        _help18n = t(helpString)
    }

    if (_help18n === undefined) {
        _help18n = (validationStatusValue === 'error' ? props.errorMessage : props.help);
    }

    const [help18n, setHelp18n] = React.useState(_help18n)
    const [validateStatus, setValidateStatus] = React.useState(_validateStatus)

    if (props.maxLength !== undefined) {
        const elements = (value ? value.length : 0);
        if (props.kind === FormKind.TEXTAREA) {
            // const value = get(store.getData(), props.path);
            _help18n = (_help18n !== undefined ? _help18n + " " : '')  + elements + " su " + props.maxLength;
        } else {
            if (elements === props.maxLength) {
                _help18n = (_help18n !== undefined ? _help18n + " " : '')  + "Raggiunta la lunghezza massima del campo: " +elements;
            }
        }
    }

    _validationStatus = (props.status ? props.status : (hasError(props.path, validateContext.errors) ? 'error': undefined));

    const [validationStatus, setValidationStatus] = React.useState(_validationStatus)


    React.useEffect(()=> {

            setValidate(props.error !== undefined ? 'error': undefined);
        if (props.error !== undefined) {
            setValidationStatus('error');
            setHelp18n(props.error);
        }

    }, [props.error]);

    React.useEffect(() => {
        if (value === undefined && (validate === 'error' || validateStatus === 'error')) {
            setValidate(undefined);
            setValidateStatus(undefined);
            setHelp18n(undefined);
            setValidationStatus(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);



    const validateHandler = React.useCallback((path: string, error: boolean | undefined, status:string)=> {

        if (error === undefined || (error !== undefined && !error)) {

            validateContext.resetValidation();
        }
        setValidationStatusValue(status)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[validationStatusValue]
    );

   // console.log('ValidateSttaus: ', validate as (typeof ValidateStatuses)[number] || validationStatusValue as (typeof ValidateStatuses)[number] || validationStatus as (typeof ValidateStatuses)[number] )
    // console.log('Help: ', help18n || props.help || props.error || undefined)
    return (
        <AntForm.Item
            className={props.className}
            label={(props.label !== undefined) ? props.label : t(`${props.path}.label`)}
            required={props.required ? props.required : false}
            validateStatus={ validate as (typeof ValidateStatuses)[number] || validationStatusValue as (typeof ValidateStatuses)[number] || validationStatus as (typeof ValidateStatuses)[number] }
            help={help18n || props.help || props.error || undefined}

            tooltip={props.help}
            style={{width: '100%', padding: '4px', textAlign: 'left'}}
            hasFeedback={props.kind !== FormKind.CHECKBOX && ((props.asRadioButtons === undefined || (props.asRadioButtons !== undefined && !props.asRadioButtons)))}
        >
            <ActualFormItem
                validateHandler={validateHandler}
                {...props}
            />
        </AntForm.Item>
    )
};