import AuthoredResource from "./AuthoredResource";
import get from 'lodash/get'
import Utils from "./Utils";
import {Resource} from "@rest-hooks/rest";
import {AbstractInstanceType} from "@rest-hooks/normalizr";
import Address from "./Address";

/*
{
"address": {
        "cap": "80056",
        "comune": "Ercolano",
        "indirizzo": "Corso Resina",
        "provincia": "NA"
    },
    "codiceFiscale": "95234870632",
    "codiceIpa": "m_bac",
    "codiceUO": "Z7CU6N",
    "denominazione": "Ministero della Cultura - Parco Archeologico di Ercolano",
    "exists": true,
    "pecs": [
        "test-uo@pec.it"
    ]
      }

{"codiceIpa":"m_bac","denominazione":"Ministero della Cultura","codiceFiscale":"97904380587",
"pecs":["mbac-udcm@mailcert.beniculturali.it"],
"address":{"comune":"Roma",
"provincia":"RM",
"indirizzo":"Via del Collegio Romano 27","cap":"00186"},"exists":false}



 */

export default class AmministrazioniValidate extends AuthoredResource {
    readonly codiceFiscale: string = ''
    readonly codiceIpa: string = ''
    readonly codiceUO: string = ''
    readonly denominazione: string = ''
    readonly exists: boolean = false
    readonly pecs: string[] = []
    readonly address: Address = new Address()

    pk() {return this.codiceIpa + (this.codiceUO ?? '')}

    static url<T extends typeof Resource>(
        this: T,
        urlParams: { codiceIpa: string } & Partial<AbstractInstanceType<T>>,
    ): string {
        if (urlParams) {

            if (this.pk(urlParams) !== undefined) {

                let sParams = new URLSearchParams()

                for (let u in urlParams) {
                    sParams.append(u, get(urlParams, u))
                }

                return `${Utils.govWeb()['urlRoot']}/${Utils.govWeb()['amministrazioniValidateCheck']}?${sParams.toString()}`;
            }
        }
        // since we're overriding the url() function we must keep the type the
        // same, which means we might not get urlParams
        throw new Error('Comments require at least codiceIpa to retrieve');
    }



    static urlRoot = `${Utils.govWeb()['urlRoot']}/${Utils.govWeb()['amministrazioniValidateCheck']}`;
}