import {Resource} from '@rest-hooks/rest';


class NetworkError extends Error {
    private  status: number;
    private  response: Response;
    name = 'NetworkError';

    constructor(response: Response) {
        super(
            response.statusText ||
            /* istanbul ignore next */ `Network response not 'ok': ${response.status}`,
        );

        // console.log('>>>Error: ', response)
        this.status = response.status;
        this.response = response;

        try {
            response.json().then(json => {
            //    console.log('ERRORMSG: ', json)
                this.status = json.code;

            }).catch( _e => {
           //     console.log('ERRRRROOOORRREEE ', _e)
            })

        } catch (e) {
            //console.log('ERRRRROOOORRREEE ', e)
        }

    }
}


// Wrapper fetch che permette di controllare dapprima se il token è ancora valido, richiedendone il token aggiornato.
// Richiama la fetch presente nella callback.

const refreshToken = async (keycloak: Keycloak.KeycloakInstance) => {

    return new Promise<Keycloak.KeycloakInstance>((resolve, reject) => {

        keycloak.updateToken(5).then(function () {
            resolve(keycloak)
        }).catch(function () {
            reject(keycloak)
        });
    });
};


abstract class AuthoredResource extends Resource {
    private static token: any | undefined = undefined;
    public static setToken(token: any | undefined) {
        //console.log('SetTOKEN: ', token);
        AuthoredResource.token=token
    };
    static getFetchInit = (init: RequestInit): RequestInit => {
    refreshToken(AuthoredResource.token)
        .then((tkn: any) => {
          //  console.log('REfreshed.. WOW')
            AuthoredResource.setToken(tkn)
        })
        .catch((error)=> {
            AuthoredResource.token.login()
        })
    return (
        {
            ...init,
            headers: {
                ...init.headers,
                'Authorization': `Bearer ${AuthoredResource?.token?.token}`,
            }
        }
    );
    }


    /** Perform network request and resolve with HTTP Response */
    static fetchResponse(input: RequestInfo, init: RequestInit) {
        let options: RequestInit = init;
        if (!options.body || typeof options.body === 'string') {
            options = {
                ...options,
                headers: {
                    'Content-Type': 'application/json',
                    ...options.headers,
                },
            };
        }
        return fetch(input, options)
            .then(response => {
                if (!response.ok) {
                    console.log('response: ', response)
                    throw new NetworkError(response);
                }
                return response;
            })
            .catch(error => {
                // ensure CORS, network down, and parse errors are still caught by NetworkErrorBoundary
                if (error instanceof TypeError) {
                    (error as any).status = 400;
                }
                throw error;
            });
    }


    /** Perform network request and resolve with json body */
    static fetch(input: RequestInfo, init: RequestInit) {
        //console.log('fetch: ', input, init)
        return this.fetchResponse(input, init).then((response: Response) => {
           // console.log('headers: ', response, response.headers, response.body)
            return response.json().catch(error => {
                error.status = 400;
                throw error;
            });
        });
    }

}
export default AuthoredResource;