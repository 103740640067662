import React from 'react';
import {Divider} from "antd";

import {Div, MediaQuery} from "@nic/ui-comps";


interface TitleInterface {
    title: string;
    marginTop?: string;
    marginBottom?: string;
}

const Title: React.FunctionComponent<TitleInterface> = (props) => {
    const xtraLargeDisplayTitle = () =>
        <Divider
            style={
                {
                    fontSize: '1.2rem',
                    fontWeight: 500,
                    marginBottom: (props.marginBottom !== undefined ? props.marginBottom : '0px'),
                    marginTop: (props.marginTop !== undefined ? props.marginTop :'0px')
                }
            }
        >{props.title}
        </Divider>;

    const largeDisplayTitle = () =>
        <Divider style={{fontSize: '1rem', fontWeight: 500, marginBottom: '0px', marginTop: '0px'}}>{props.title}</Divider>;

    const mediumDisplayTitle = () =>
        <Div fontWeight={'800'}>{props.title}</Div>;

    const xtraSmallDisplayTitle = () =>
        <Div fontWeight={'800'}>{props.title}</Div>;

    const smallDisplayTitle = () =>
        <Div fontWeight={'800'}>{props.title}</Div>;

    return (
    <>
        <MediaQuery break={'xxl'}>
            {xtraLargeDisplayTitle()}
        </MediaQuery>
        <MediaQuery break={'xl'}>
            {xtraLargeDisplayTitle()}
        </MediaQuery>
        <MediaQuery break={'l'}>
            {largeDisplayTitle()}
        </MediaQuery>
        <MediaQuery break={'m'}>
            {mediumDisplayTitle()}
        </MediaQuery>
        <MediaQuery break={'xs'}>
            {xtraSmallDisplayTitle()}
        </MediaQuery>
        <MediaQuery break={'sm'}>
            {smallDisplayTitle()}
        </MediaQuery>
    </>
    )
};

export default Title;