import React from 'react'
import UnitaOperative from "../api/UnitaOperative";
import {addressSimple, field} from "../utils";
import {Flex, VerticalLayout} from "@nic/ui-comps";

interface UnitaOperativaViewerI {
    uo?: UnitaOperative
}


const UnitaOperativaViewer: React.FC<UnitaOperativaViewerI> = (props) => {
    const {uo} = props
    const _address = uo?.address
    return (

        <VerticalLayout style={{gap: 20, alignItems: 'flex-start'}}>
        {uo && field('Codice Unità Organizzativa', uo.codiceUO)}
            {uo && field('Denominazione', uo.denominazione)}
            {uo && field('Codice IPA', uo.codiceIpa)}


                {uo && uo.codiceFiscale.length > 0 && field('Codice Fiscale', uo.codiceFiscale)}

                { _address &&
                    <Flex direction={'column'} justify={'flex-start'} align={'flex-start'}>
                {field('Indirizzo')}
                {addressSimple(_address.indirizzo, _address.cap, _address.comune, _address.provincia)}
                    </Flex>}
        </VerticalLayout>

    )
}
export default UnitaOperativaViewer;
