import {Div, Flex} from "@nic/ui-comps";

export function emptyData(obj: Object) {
    return (obj === undefined || (obj !== undefined && Object.keys(obj).length === 0))
}


export function field(label: string, value?: string, direction?: string, justify?: string, align?:string) {
    const dir = (direction ??'column')
    const _justify = justify ?? 'flex-start'
    return (
        <Flex direction={dir} justify={_justify} align={'flex-start'}>
            <Div>{`${label}:`}</Div>
            {value !== undefined && <Div fontWeight={'700'}>{value}</Div>}
        </Flex>
    )
}


export function Value(value?: string) {
    return (
        <>{value !== undefined && <Div fontWeight={'700'}>{value}</Div>}</>
    )
}


export function addressSimple(indirizzo:string, cap: string, comune: string, provincia: string) {
    return (
        <Flex direction={'row'} justify={'flex-start'} align={'flex-start'} style={{gap: 5}}>
        <Div fontWeight={'700'}>{indirizzo}</Div>

            <Div fontWeight={'700'}>{cap}</Div>
            <Div fontWeight={'700'}>{comune}</Div>
            <Div fontWeight={'700'}>{provincia}</Div>
        </Flex>
    )
}

export function concatAddress(indirizzo:string, cap: string, comune: string, provincia: string) {
    return (
        <Flex direction={'row'} justify={'flex-start'} align={'flex-start'} style={{gap: 5}}>
            <Div fontWeight={'700'}>{indirizzo}</Div>
            <Div fontWeight={'700'}>{cap}</Div>
            <Div fontWeight={'700'}>{comune}</Div>
            <Div fontWeight={'700'}>{provincia}</Div>
        </Flex>
    )
}


export function addressField(label: string, indirizzo:string,
                             cap: string, comune: string, provincia: string,
                             direction?: string, justify?: string) {
    const dir = (direction ??'column')
    const _justify = justify ?? 'flex-start'
    return (
        <Flex direction={dir} justify={_justify} align={'flex-start'}>
            <Div>{`${label}:`}</Div>
            {concatAddress(indirizzo, cap, comune, provincia)}
        </Flex>
    )
}