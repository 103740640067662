import React from 'react';
import {useLocation} from "react-router-dom";
import WarningInfo from "./WarningInfo";

interface WrongPathI {
    asCard?: boolean;
}


const WrongPath: React.FC<WrongPathI> = (props) => {

    let location= useLocation()
    
    const msg = `Path ${location.pathname} inesistente`;

    return (
        <WarningInfo msg={msg} asCard={props.asCard}/>
    )
}

export default WrongPath;