import React from 'react'
import {useKeycloak} from "@react-keycloak/web";
import {Div, Flex} from "@nic/ui-comps";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPowerOff} from "@fortawesome/free-solid-svg-icons";

interface HeaderI {
    title: string
}


const Header: React.FC<HeaderI> = (props) => {
    const {keycloak} = useKeycloak();
    const {title} = props
    //const navigate = useNavigate();
    return (
        <Flex direction={'row'} justify={'space-between'} style={{gap: 10}} width={'100%'} >


            <Flex direction={"row"}  justify={"flex-start"} style={{flexWrap:'nowrap',gap: 5}}>
            <Div
                paddingLeft={'10px'}
                fontSize={'2rem'}
            >
                <a style={{color: 'white', paddingRight:'5px'}} href={'/'}>{title}</a>
                <img
                    height={'30px'}
                    width={'auto'}
                    alt={'logo'}
                    src={'/images/logoRegistroWhite.svg'}
                />
            </Div>

            </Flex>
            <FontAwesomeIcon
                style={{
                    fontWeight: '1.1rem',
                    cursor: 'pointer',
                    paddingRight: '25px'
                }}
                color={'white'}
                icon={faPowerOff}
                onClick={()=>keycloak.logout({redirectUri: window.location.origin})}
            />
        </Flex>
    )
}

export default Header