import React from 'react'
import {Div, Flex, HorizontalLayout, MediaQuery} from "@nic/ui-comps";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopyright} from "@fortawesome/free-regular-svg-icons";

interface FooterI {}

const Footer: React.FC<FooterI> = (props) => {
    const year =
        Intl.DateTimeFormat('it-IT', {

            year: 'numeric',

        }).format(new Date());

    const foooterInfo = () => {
        return (
            <>
                <Div marginRight={'20px'}>
                    <img style={{marginRight: '10px'}} height={'30px'} width={'auto'} alt="gov.it" src={'/images/gov.it.svg'}/>
                </Div>
        <Div marginRight={'20px'}>
            <FontAwesomeIcon color={'gray'} icon={faCopyright} style={{marginRight: '5px'}}/>
            <span style={{fontWeight: 700}}>{year}</span>
        </Div>
        <Div>
        <a rel="noreferrer" target="_blank" href={'https://www.nic.it'}><img style={{marginRight: '10px'}} height={'30px'} width={'auto'} alt="Registro" src={'/images/logoRegistroNew.svg'}/></a>
        </Div>
        <Div>
            <a  rel="noreferrer" target="_blank" href={'https://www.agid.gov.it/'}><img style={{marginRight: '10px'}} height={'30px'} width={'auto'} alt="AGID" src={'/images/AgID_blue.svg'}/></a>
        </Div>
                </>
    )
    }


    return (
        <>
       <MediaQuery break={'xxl'}>
           <HorizontalLayout style={{paddingTop: '10px', paddingBottom: '10px', justifyContent: 'center', alignItems: 'center', gap: 20}}>
               {foooterInfo()}
           </HorizontalLayout>

        </MediaQuery>

            <MediaQuery break={'xl'}>
                <HorizontalLayout style={{paddingTop: '10px', paddingBottom: '10px', justifyContent: 'center', alignItems: 'center', gap: 20}}>
                    {foooterInfo()}
                </HorizontalLayout>

            </MediaQuery>

            <MediaQuery break={'l'}>
                <HorizontalLayout style={{paddingTop: '10px', paddingBottom: '10px', justifyContent: 'center', alignItems: 'center', gap: 20}}>
                    {foooterInfo()}
                </HorizontalLayout>

            </MediaQuery>

            <MediaQuery break={'m'}>
                <Flex direction={'row'} style={{paddingTop: '10px', paddingBottom: '10px', justifyContent: 'center', alignItems: 'center', gap: 20}}>
                    {foooterInfo()}
                </Flex>

            </MediaQuery>

            <MediaQuery break={'xs'}>
                <Flex direction={'row'} style={{paddingTop: '10px', paddingBottom: '10px', justifyContent: 'center', alignItems: 'center', gap: 20}}>
                    {foooterInfo()}
                </Flex>

            </MediaQuery>
            <MediaQuery break={'s'}>
                <Flex direction={'row'} style={{paddingTop: '10px', paddingBottom: '10px', justifyContent: 'center', alignItems: 'center', gap: 20}}>
                    {foooterInfo()}
                </Flex>

            </MediaQuery>
        </>
    )
}

export default Footer