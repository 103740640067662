import React from 'react';
import {FormItem, FormKind, SelectModeValues, TextTransformation} from "../components/form/FormItem";

import {ValidateContext} from "../context/ValidateContext";

import {Button, Tooltip, Tabs, BackTop} from "antd";
import ArrayContainer from "./ArrayContainer";

import get from 'lodash/get';
import {faBroom, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {DomainValidator, ItDomainValidator} from "../utils/DomainValidator";
import {ACTIONS, StepFunctionType, Wizard} from "./Wizard";
//import {ACTIONS, buildContext, IStepContext, ParametersInterface} from "../forms/Constants";
import set from "lodash/set";

import moment from "moment";
import {convertBoolean} from "../constants";
import {IStepContext, ParametersInterface} from "../types";
import {buildContext, fooT} from "../utils/utils";
import {HorizontalLayout, VerticalLayout, PaperLayout, Flex} from "@nic/ui-comps";

//import {useTranslation} from "react-i18next";
import {IStoreContext, StoreContext} from "../../../context/FormContext";
import Description from "../components/Description";
import {GroupLayout} from "../components/layout/GroupLayout";

type Map<T> = Record<string, T>;

export enum LayoutKind {
    VERTICAL="Vertical",
    HORIZONTAL="Horizontal",
    CONTROL="Control",
    PAPER="Paper",
    GROUP="Group",
    ARRAY="Array",
    EMBEDDED="Embedded"
}


export interface FormError {
    path: string;
    type: string;

}


export enum SchemaType {
    ARRAY='array',
    OBJECT='object',
    STRING='string',
    TEXTAREA='textarea',
    NUMBER='number',
    INTEGER='integer',
    BOOLEAN='boolean',
    ENUM='enum',
}


type PatternFormat =
    'password' |
    'email' |
    'url' |
    'date' |
    'time' |
    'date-time' |
    'ipv4' |
    'ipv6' |
    'domain' |
    'domain.it' |
    'year' |
    string;

export interface Schema {
    name?: string;
    description?: string;
    type: SchemaType;
    properties?: Map<Schema>;
    values?: Array<any>;
    items?: Schema;
    required?: boolean;
    format?: PatternFormat;
}
/*

const example: Schema = {
    name: 'pio',
    type: SchemaType.OBJECT,
    properties: {
        mamma: {
            type: SchemaType.ARRAY,
            required: true,
            items: {
                name: 'root',
                type: SchemaType.OBJECT,
                properties: {
                    bella: { type: SchemaType.STRING, required: true},
                    zia: {type: SchemaType.ENUM, values: ['19', '20', '23']}
                }
            }
        },
        bella: {
            required: true,
            type: SchemaType.STRING,
        },
        zoppa: {
            type: SchemaType.ENUM,
            values: ['mamma', 'bella', 'zoppa', 'molla', 'polla', 'cocca']
        },
        palla: {
            type: SchemaType.ARRAY,
            required: true,
            items: {
                required: true,
                type: SchemaType.STRING
            }
        }
    }
};
*/
interface LayoutElement {
    kind: LayoutKind;
    path?: string;
    title?: string;
}

export interface Layout extends  LayoutElement {
    elements?: Array<Layout>
}

export interface UIOption {
    trim?: boolean;
    textTransform?: TextTransformation;
    multi?: boolean;
    stepped?: boolean;
    detail?: UILayout;
    allowClear?: boolean;
    asRadioButtons?: boolean;
    showOnlyBusinessDays?:boolean;
    tabbed?:boolean;
    maxLength?: number;
}

export interface UIConditionValue {
    path: string;
    value: string | boolean | Array<any>
}

export interface  UIConditionSpec {
    path: string;
    type?: 'OR' | 'LEAF';
    valIe?: UIConditionValue | Array<UIConditionValue>;
    values?: Array<UIConditionValue>;
}


export enum UIOperation {
    PATTERN = 'pattern',
    EQ = 'eq',
    NEQ = 'neq',
    LT = 'lt',
    LTE = 'lte',
    GT = 'gt',
    GTE = 'gte'
}

export interface UIPath {
    path: string;
}

export interface UICondition {
    or?: Array<UICondition>;
    and?: Array<UICondition>;
    not?: UICondition;
    operation?: UIOperation;
    value?: string | boolean | number | UIPath;
    path?: string;
}

export interface UISimpleCondition {
    operation: string;

}

export enum UIEffect {
    LEAF='leaf',
    SHOW='show',
    HIDE='hide'
}

export interface UIRule {
    effect: UIEffect;
    condition: UICondition;
    values?: Array<any>;
}

export interface ValidatorResult {
    status: boolean;
    error?: string;
}

export interface UILayout {
    elements?: Array<UILayout>;
    rule?: UIRule;
    options?: UIOption;
    kind: LayoutKind;
    path?: string;
    label?: string;
    error?: string;
    required?:boolean;
    success?: string;
    placeholder?: string;
    message?: string;
    title?:string;
    description?:string | React.ReactElement;
    descriptionKind?: 'info' | 'warning' | 'error';
    format?: PatternFormat;
    values?: Array<any>;
    embeddedComponent?: React.ReactElement<any>;
    validate?:boolean;
    readonly?: boolean;
    help?: string;
    validator?: (data: any) => Array<FormError>;
}

export interface FormDefinition {
    path: string;
    kind: FormKind;
    context: React.Context<IStoreContext>;
    required?:boolean;
    placeholder?:string;
    status?:string;
    validator?: (value: any) => boolean;
    textTransformation?: TextTransformation;
    rendered?: (context: IStoreContext, t:(key:string)=>string)=>boolean;
    optionValues?: Array<string>;
    translationPrefix?: string;
}

type DataHandler = (data: any) => void;


export interface FormInterface {
    context: React.Context<IStoreContext>;
    store: IStoreContext;
    elements?: Array<FormDefinition>;
    layout?: Layout;
    //handleAction: (data: any) => void;
    handleAction: DataHandler;
    loading?: boolean;
    onReset: () => void;
    clean?: () => void;
    useTranslation?: boolean;
    schema: Schema;
    uischema: UILayout;
    validate?: (data:any) => Array<FormError>;
    errors?: Array<FormError>;
    okButton?: string;
    okButtonDisabled?: boolean;
    resetButton?: string;
    showButtons?:boolean;
    handleCancel?: () => void;
    t?: (label: string) => string;
    className?: string;
}


export interface FormLayout {
    path: string;
    kind: FormKind;
    placeholder?: string;
    elements?: Array<FormLayout>;
    // Restriction
    // Error finction
    //
}
let layoutMap: Map<UILayout> = {};

export const visitUILayout = (schema: Schema, uiLayout: UILayout, store:IStoreContext, layoutMap: Map<UILayout>) => {
    // console.log('Kind: ', uiLayout.kind, ' path:',uiLayout.path);
    if (uiLayout.path) {
        // Devo vedere se è un campo condizionato e se deve essere in questo momento preso in considerazione

        if (uiLayout.rule !== undefined) {

            let toStore = evaluateCondition(uiLayout.rule.condition, store.getData());

            if (toStore) {

                layoutMap[uiLayout.path] = uiLayout;
            }

        } else {
            //  console.log('layoutMap: ', uiLayout.path, uiLayout);
            layoutMap[uiLayout.path] = uiLayout;
        }
    }
    switch(uiLayout.kind) {
        case LayoutKind.CONTROL:
            break;
        case LayoutKind.VERTICAL:
        case LayoutKind.HORIZONTAL:
        case LayoutKind.GROUP:
        case LayoutKind.PAPER:
            if (uiLayout.elements) {
                uiLayout.elements.forEach((element) => {
                    visitUILayout(schema, element, store, layoutMap);
                });
            }
            break;
        case LayoutKind.ARRAY:
            if (uiLayout.elements) {
                uiLayout.elements.forEach((element) => {
                    visitUILayout(schema, element, store, layoutMap);
                });
            }
            break;
        default:
            break;
    }
};

const schemaMap: Map<Schema> = {};

export const visitSchema =  (path: string, schema: Schema) => {
    // console.log('Visit Schema: ', path);
    switch ( schema.type ) {

        case SchemaType.ENUM:
            schemaMap[path]=schema;
            break;

        case SchemaType.OBJECT: {
            let  fullPath = (path !== undefined  && path.length > 0? path : '');
            let schemaPath = (schema.name !== undefined) ? schema.name : '';
            fullPath += (schemaPath.length > 0 && fullPath.length > 0 ? '.' + schemaPath : schemaPath);

            if (schema.name) {
                schemaMap[fullPath]=schema;
            }

            if (schema.properties) {
                for (let prop in schema.properties) {
                    // console.log('Visit object props: ', prop, schema.properties[prop]);
                    visitSchema((fullPath.length > 0 ? fullPath + '.' + prop : prop),  schema.properties[prop]);
                }
            }
            break;
        }

        case SchemaType.STRING:
        case SchemaType.INTEGER:
        case SchemaType.NUMBER:
        case SchemaType.BOOLEAN:
        case SchemaType.TEXTAREA:
            schemaMap[path] = schema;
            break;
        case SchemaType.ARRAY:
            let fullPath =  (path !== undefined && path.length > 0) ?  path :  '';
            fullPath += (schema.name !== undefined ? '.' + schema.name : '');

            schemaMap[fullPath] = schema;
            //        console.log('Array: fullPath', fullPath, schema);
            /*
                        if (schema.items) {
                            // console.log('Array items: ', schema.items, ' fullpath: ', fullPath);
                            visitSchema((fullPath !== undefined ? fullPath : ''), schema.items);
                        }
            */
            break;
    }
};

type ReactElement =  Array<React.ReactElement<any>> | React.ReactElement<any>;

export const buildLayout = (layout: Layout, component: ReactElement) => {

    if (layout === undefined)
        return <>{component}</>;

    switch (layout.kind) {
        case LayoutKind.HORIZONTAL:
            return <HorizontalLayout>{component}</HorizontalLayout>;
        case LayoutKind.VERTICAL:
            return <VerticalLayout>{component}</VerticalLayout>;
        case LayoutKind.GROUP:
            return <GroupLayout>{component}</GroupLayout>;
        default:
            return <>{component}</>;
    }
};


export const validate = (path: string, schema: Schema, data: any,  layoutMap: Map<UILayout>, useTranslation?: boolean): Array<FormError> => {
    let errors: Array<FormError> = [];
    const dataValue = get(data, path);

   // console.log('Validate ', path, schema, data, layoutMap, dataValue)

    const thePath: string | undefined =
        (useTranslation !== undefined && !useTranslation ? (layoutMap[path] !== undefined ? layoutMap[path].label : undefined) : path);

    const realPath: string = (thePath !== undefined ? thePath : path);

    switch (schema.type) {
        case SchemaType.OBJECT: {
            let baseField = (path.length > 0 ? path : '');
            baseField +=(baseField.length >0 && schema.name !== undefined ? '.': '');
            baseField +=(schema.name !== undefined ? schema.name : '');

            if (schema.properties !== undefined) {
                baseField += (baseField.length > 0 ? '.' : '');
                for (let p in schema.properties) {
                    // Devo controllare se camèp è obbliatorio
                    const errList =validate(baseField + p, schema.properties[p], data, layoutMap, useTranslation);
                    errors = errors.concat(errList);
                }
            }
            break;
        }
        case SchemaType.NUMBER: {

            if (schema.required !== undefined && schema.required && (dataValue === undefined || dataValue.length === 0)) {
                errors.push({path: realPath, type: 'Campo Obbligatorio'} as FormError);
            }

            if (dataValue !== undefined && (typeof dataValue !== "number")) {
                errors.push({path: realPath, type: 'typeMismatch'})
            }

            break;
        }
        case SchemaType.BOOLEAN: {
            if (schema.required !== undefined && schema.required && (dataValue === undefined || dataValue.length === 0)) {
                errors.push({path: realPath, type: 'Campo Obbligatorio'} as FormError);
            }

            let value = dataValue;

            if (value !== undefined && (typeof value === "string")) {
                value = convertBoolean[value];
            }

            //         console.log('value: ', value, ' typeof ', typeof value);

            if (value !== undefined && (typeof value !== "boolean")) {
                // console.log('--->value: ', value, ' typeof ', typeof value);
                errors.push({path: realPath, type: 'typeMismatch'})
            }
            break;
        }

        case SchemaType.STRING: {

            if (schema.required !== undefined && schema.required && (dataValue === undefined || dataValue.length === 0)) {
                //console.log(' LayoutMap: ', layoutMap, path)
                const errorMsg = layoutMap[path].error ?? 'Campo Obbligatorio';
                errors.push({path: realPath, type: errorMsg} as FormError);
            }

            if (dataValue !== undefined && dataValue !== null) {

                if (schema.format !== undefined) {
                    let formatError = false;
                    switch (schema.format) {
                        case 'date':
                        case 'time':
                        case 'date-time':
                            formatError = (!moment(dataValue).isValid());
                            break;
                        default:
                            formatError = (typeof  dataValue !== 'string');
                            break;
                    }



                    if (formatError) {

                        errors.push({path: realPath, type: 'typeMismatch'});
                    }
                } else {

                    if (typeof dataValue !== 'string') {

                        // console.log('typeof-> ', typeof dataValue, dataValue);
                        errors.push({path: realPath, type: 'typeMismatch'});
                    }

                }

            }

            if (dataValue !== undefined && dataValue.length > 0) {

                const uiLayoutPattern: string | undefined = layoutMap[path]?.format;
                let pattern: string | undefined = schema.format;
                pattern = (pattern === undefined && uiLayoutPattern !== undefined ? uiLayoutPattern : pattern);

                if ((pattern !== undefined) && !validatePattern(pattern, dataValue)) {
                    errors.push({
                        path: realPath,
                        type: layoutMap[path] !== undefined && layoutMap[path].error !== undefined ? layoutMap[path].error : 'Valore non valido'
                    } as FormError);
                }
            }

            break;
        }
        case SchemaType.ENUM: {

            if (schema.required !== undefined && schema.required && (dataValue === undefined || dataValue.length === 0)) {
                errors.push({path: realPath, type: 'Campo Obbligatorio'} as FormError);
            }
            break;
        }
        case SchemaType.ARRAY:

            let baseField = (path.length > 0 ? path : '');
            baseField +=(baseField.length >0 && schema.name !== undefined ? '.': '');
            baseField +=(schema.name !== undefined ? schema.name : '');

            if (schema.items !== undefined) {
                //baseField += (baseField.length > 0 ? '.' : '');
                //console.log('ArrayField: ', schema.items);
                // Devo controllare se camèp è obbliatorio
                const errList =validate(baseField, schema.items, data, layoutMap, useTranslation);
                errors = errors.concat(errList);
            }



            break;
        default: {
            //console.log('Default: ', path, data, schema);
            break;
        }
    }

    return errors;
};


const getFormKindFromText = (schema: Schema): FormKind => {
    switch (schema.format) {
        case  'date':
        case  'time':
        case  'date-time':
            return FormKind.DATE;
        case 'password':
            return FormKind.PASSWORD;
        default:
            return FormKind.TEXT;
    }
};

const getLayoutFromSchema = (path: string) => {
    //  console.log('path: ', path, ' --> ', schemaMap[path]);
    if (schemaMap[path] !== undefined) {
        switch (schemaMap[path].type) {
            case SchemaType.STRING:
                return getFormKindFromText(schemaMap[path]);
            case SchemaType.TEXTAREA:
                return FormKind.TEXTAREA;
            case SchemaType.NUMBER:
                return FormKind.NUMBER;
            case SchemaType.ENUM:
                return FormKind.SELECT;
            case SchemaType.ARRAY:
                return FormKind.ARRAY;
            case SchemaType.BOOLEAN:
                return FormKind.CHECKBOX;
        }
    }
};

const getComponents = (errorsMap: Map<FormError>,
                       schema: Schema, uiSchema: UILayout, layout: FormInterface,
                       context: React.Context<IStoreContext>, key: string,
                       useTranslation: boolean, handleAction: DataHandler, store: IStoreContext,
                       errorsSetter: (errors: Array<FormError>) => void,
                       t: (label: string) => string,
                       className: string) => {

    let components: Array<React.ReactElement<any>> = [];


    if (uiSchema.elements !== undefined) {

        uiSchema.elements.forEach((element, idx) => {
            const myPath = (element.path !== undefined ? element.path : '');
            const component = buildFieldFromUISchema(className, errorsMap, schema, myPath, element, layout, context, useTranslation, handleAction, store, errorsSetter, t, key+idx);
            if (component !== undefined) {
                components.push(component);
            }
        });
    }
    return components;
};


export const validatePattern = (pattern: string, data: any): boolean => {
    let patternString: string = '';
    // console.log('ValidatePattern: ', pattern, data);
    switch(pattern) {
        case 'email':
            patternString =
                '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])';
            break;
        case 'url':
            break;
        case 'ipv4':
            break;
        case 'ipv6':
            break;
        case 'domain.it':
            return ItDomainValidator.validate(data).valid;
        case 'domain':
            return DomainValidator.validate(data).valid;
        case 'date':
            break;
        case 'time':
            break;
        case 'data-time':
            break;
        case 'phone-number':
            patternString = '^\\+[0-9]+\\.\\d{1,14}$';
            break;
        case 'year':
            patternString = '^(19\\d{2})|(2\\d{3})$';
            break;
        case 'password':

//            patternString = '^(?=.*[0-9])(?=.*[!@#$%^&£*/()=?,;"._-\]\[])[a-zA-Z0-9!@#$%^&£*/()=?,;"._-\]\[]{8,64}$';
            patternString = '^(?=.*[0-9])(?=.*[!@#$%^&£€*\\/()=?,;"._\\{\\}\\[\\]\\^\\\\\\|-])[a-zA-Z0-9!@#$%^&€£*\\/()=?,;"._\\{\\}\\]\\[\\^\\\\\\|-]{8,64}$'
            break;
        default:
            patternString = pattern;
            break;
    }


    let regExp = new RegExp(patternString);

    return regExp.test(data);
};


//export type StepFunctionType = (context: IStepContext) => WizardStepInterface;

const validateFromUISchema = (uiElement: UILayout, schema: Schema, store: IStoreContext, useTranslation:boolean): Array<FormError> => {
    let res: Array<FormError> = [];

    switch (uiElement.kind) {
        case LayoutKind.VERTICAL:
        case LayoutKind.ARRAY:
        case LayoutKind.GROUP:
        case LayoutKind.HORIZONTAL:
            uiElement.elements && uiElement.elements.forEach((element)=> {

                const errors = validateFromUISchema(element, schema, store, useTranslation);

                res = res.concat(errors);
            });
            break;
        case LayoutKind.CONTROL:
        case LayoutKind.EMBEDDED:
        case LayoutKind.PAPER: {
            let path = uiElement.path !== undefined ? uiElement.path : '';
            let subSchema = schemaMap[path];
            //
            // console.log('ValidateFromUISchema: ', path, subSchema, schemaMap)

            if (uiElement.rule !== undefined) {
                let toValidate: boolean = evaluateCondition(uiElement.rule.condition, store.getData());
                if (toValidate) {
                    res = validate(path, subSchema !== undefined ? subSchema : schema, store.getData(), layoutMap, useTranslation);
                }

            } else {
                res = validate(path, subSchema !== undefined ? subSchema : schema, store.getData(), layoutMap, useTranslation);
            }
            break;
        }
    }

    return res;
};


const buildWizardElements = (path: string,
                             schema: Schema,
                             uiSchema: UILayout,
                             errorsMap: Map<FormError>,
                             layout: FormInterface,
                             handleAction: DataHandler,
                             context: React.Context<IStoreContext>,
                             store: IStoreContext,
                             errorsSetter: (errors: Array<FormError>) => void,
                             useTranslation:boolean,
                             t: (label: string) => string,
                             className: string): Array<StepFunctionType<ACTIONS, any>> => {

    let res = [] as Array<StepFunctionType<ACTIONS,any>>;

    uiSchema.elements && uiSchema.elements.forEach((elem, index)=> {

        let title: string = elem.title !== undefined ? elem.title : 'Passo';

        let component =
            buildFieldFromUISchema(className, errorsMap, schema, path, elem, layout, context,  useTranslation, handleAction, store, errorsSetter, t, `${index}`);

        if (component !== undefined) {


            let description: string | React.ReactElement = elem.description !== undefined ? elem.description : 'Description';

            let validation = (data: any, t: ((key: string) => string)): Map<string> => {

                let errorMap: Map<string> = {};
                let errorArray: Array<FormError> = [];
                let validatorError: Array<FormError> = [];

                if (elem.validate === undefined || (elem.validate !== undefined && elem.validate)) {

                    if (elem.validator !== undefined) {
                        validatorError = elem.validator(data);
                        errorsSetter(validatorError);
                    }

                    let errors: Array<FormError> | undefined =
                        validateFromUISchema(elem, schema, store, useTranslation);
                    let errorsForm: Array<FormError> | undefined =
                        validateFromUISchema(elem, schema, store, true);

                    errorsSetter(errorsForm);

                    if (errorsForm !== undefined) {
                        errorsForm.forEach((error) => {
                            errorMap[error.path] = error.type;
                        });
                    }

                    if (errors !== undefined) {
                        errors.forEach((error) => {
                            errorMap[error.path] = error.type;
                        });
                    }


                    if (validatorError.length !== 0) {
                        validatorError.forEach((e) => {
                            errorMap[e.path] = e.type;
                        });
                    }

                }

                for (let e in errorMap) {
                    errorArray.push({path: e, type: errorMap[e]});
                }


                // errorsSetter(errorArray);
                return errorMap;

            };

            let stepFunction = (context: IStepContext<ACTIONS, any>) => {
                return ({
                    title: title,
                    component: (component ?? <></>),
                    description: description,
                    validate: validation
                })
            };

            res.push(stepFunction)
        }
    });

    return res;
};

const getContext = ()=> {
    let queryElems = {} as ParametersInterface;
    set(queryElems, 'action', ACTIONS.NEW);
    return buildContext(queryElems);
};

const manageGroupLayout = (path: string,
                           schema: Schema,
                           uiSchema: UILayout,
                           errorsMap: Map<FormError>,
                           layout: FormInterface,
                           handleAction: DataHandler,
                           context: React.Context<IStoreContext>,
                           store: IStoreContext,
                           errorsSetter: (errors: Array<FormError>) => void,
                           useTranslation:boolean,
                           t: (label: string) => string,
                           className: string): React.ReactElement<any> => {
    if (uiSchema.options !== undefined && uiSchema.options.stepped !== undefined && uiSchema.options.stepped) {

        let steps: Array<StepFunctionType<ACTIONS, any>> =
            buildWizardElements(path, schema, uiSchema, errorsMap, layout, handleAction, context, store, errorsSetter,useTranslation, t, className);

        return (
            <Wizard
                saving={layout.loading}
                steps={steps}
                onDone={handleAction}
                context={getContext()}
                useTranslation={useTranslation}
                handleCancel={layout.handleCancel}
                t={t}
                className={className}
            />
        )
    }  else {
        if (uiSchema.options !== undefined && uiSchema.options.tabbed !== undefined && uiSchema.options.tabbed) {

            return (


                <Tabs key={path}>
                    {getTabsComponents(errorsMap, schema, uiSchema, layout, context, path, useTranslation, handleAction, store, errorsSetter, t, className)}
                </Tabs>

            )

        } else {

            return (
                <GroupLayout
                    key={path}
                    title={uiSchema.title}
                >

                    {getComponents(errorsMap, schema, uiSchema, layout, context, path, useTranslation, handleAction, store, errorsSetter, t, className)}
                </GroupLayout>
            );
        }
    }
};



const getTabsComponents = (errorsMap: Map<FormError>,
                           schema: Schema, uiSchema: UILayout, layout: FormInterface,
                           context: React.Context<IStoreContext>, key: string,
                           useTranslation: boolean, handleAction: DataHandler, store: IStoreContext,
                           errorsSetter: (errors: Array<FormError>) => void,
                           t:(label: string) => string,
                           className: string)=> {

    let components: Array<React.ReactElement<any>> = [];


    if (uiSchema.elements !== undefined) {

        uiSchema.elements.forEach((element, idx) => {
            const myPath = (element.path !== undefined ? element.path : '');
            const component = buildFieldFromUISchema(className, errorsMap, schema, myPath, element, layout, context, useTranslation, handleAction, store, errorsSetter, t, key+idx);
            if (component !== undefined) {
                components.push(
                    <Tabs.TabPane tab={element.title} key={idx}>
                        <div>
                            <Description description={element.description} kind={element.descriptionKind}/>
                            {component}
                        </div>
                    </Tabs.TabPane>
                );
            }
        });
    }
    return components;
};




const evaluateBasicCondition = (condition: UICondition, data: any): boolean => {
    let res: boolean = false;
    if (condition.operation !== undefined && condition.value !== undefined && condition.path !== undefined) {

        const dataValue: any = get(data, condition.path);

        switch (condition.operation) {
            case UIOperation.EQ:
                res = condition.value === dataValue;
                break;
            case UIOperation.GT:
                res = condition.value > dataValue;
                break;
            case UIOperation.GTE:
                res = condition.value >= dataValue;
                break;
            case UIOperation.NEQ:
                res = condition.value !== dataValue;
                break;
        }
    }

    return res;
};


const evaluateOrBoolOperation = (condition: UICondition, data: any): boolean => {
    let res = false;

    if (condition.or !== undefined) {
        condition.or.forEach((cond)=> {

            if (cond.path !== undefined)
                res = res || evaluateBasicCondition(cond, data);
        });
    }

    return res;
};

const evaluateAndBoolCondition = (condition: UICondition, data: any): boolean => {

    let res = true;

    if (condition.and !== undefined) {
        condition.and.forEach((cond) => {
            if (cond.path !== undefined) {
                res = res && evaluateBasicCondition(cond, data);
            }
        });
    }

    return res;
};

const evaluateNotCondition = (condition:UICondition, data: any): boolean => {
    let res = true;

    if (condition.not !== undefined) {
        res = ! evaluateBasicCondition(condition.not, data);
    }

    return res;
};


export const evaluateCondition = (condition:UICondition, data: any): boolean => {
    let res: boolean = false;

    if ((condition.or !== undefined)) {
        res = evaluateOrBoolOperation(condition, data);
    } else {

        if ((condition.and !== undefined)) {
            res = evaluateAndBoolCondition(condition, data);
        } else {

            if ((condition.not !== undefined)) {
                res = evaluateNotCondition(condition, data);
            } else {



                //      const condValue = get(store.getData(), uiSchema.rule.condition.path);
                res = evaluateBasicCondition(condition, data);

            }
        }
    }
    return res;
};


const evaluateRule = (uiSchema: UILayout, store: IStoreContext): boolean => {
    let res: boolean = true;
    if (uiSchema.rule !== undefined) {

        if ((uiSchema.rule.condition.or !== undefined)) {
            res = evaluateOrBoolOperation(uiSchema.rule.condition, store.getData());
        } else {

            if ((uiSchema.rule.condition.and !== undefined)) {
                res = evaluateAndBoolCondition(uiSchema.rule.condition, store.getData());
            } else {

                if ((uiSchema.rule.condition.not !== undefined)) {
                    res = evaluateNotCondition(uiSchema.rule.condition, store.getData());
                } else {
                    //      const condValue = get(store.getData(), uiSchema.rule.condition.path);
                    res = evaluateBasicCondition(uiSchema.rule.condition, store.getData());

                }
            }
        }

        /*

                                switch (uiSchema.rule.condition.operation) {
                                    case UIOperation.EQ:
                                        res = uiSchema.rule.condition.value === condValue;
                                        break;
                                    case UIOperation.GT:
                                        res = uiSchema.rule.condition.value > condValue;
                                        break;
                                    case UIOperation.GTE:
                                        res = uiSchema.rule.condition.value >= condValue;
                                        break;
                                    case UIOperation.NEQ:
                                        res = uiSchema.rule.condition.value !== condValue;
                                        break;
                                }

        */
        switch (uiSchema.rule.effect) {
            case UIEffect.HIDE:
                res = !res;
                break;
            case UIEffect.SHOW:
            case UIEffect.LEAF:
                break;
        }

    }
    return res;
};


const buildFieldFromUISchema = (className: string,
                                errorsMap: Map<FormError>,
                                schema: Schema,
                                path: string,
                                uiSchema: UILayout,
                                layout: FormInterface,
                                context: React.Context<IStoreContext>,
                                useTranslation:boolean,
                                handleAction: DataHandler,
                                store: IStoreContext,
                                errorsSetter: (errors: Array<FormError>) => void,
                                t: (label: string) => string,
                                key?: string): (React.ReactElement<any> | undefined) => {

    const schemaElem = schemaMap[path];

    const evaluateField: boolean = evaluateRule(uiSchema, store);

    if (evaluateField) {

        switch (uiSchema.kind) {
            case LayoutKind.EMBEDDED:
                return <div className={className} key={key} style={{width: '100%'}}>{uiSchema.embeddedComponent}</div>;
            case LayoutKind.HORIZONTAL:
                return <HorizontalLayout
                    className={className}
                    key={key}>{getComponents(errorsMap, schema, uiSchema, layout, context, path, useTranslation, handleAction, store, errorsSetter, t, className)}</HorizontalLayout>;
            case LayoutKind.GROUP: {
                /*
                return <GroupLayout key={path}
                                    title={uiSchema.title}>{getComponents(errorsMap, schema, uiSchema, layout, context, path, useTranslation)}</GroupLayout>;
                                    */
                return manageGroupLayout(path, schema, uiSchema, errorsMap, layout, handleAction, context, store, errorsSetter, useTranslation, t, className);
            }

            case LayoutKind.ARRAY: {

                const formKind = getLayoutFromSchema(path);

                //
                //         console.log('SchemaElem: ', schemaElem, ' uiSchema ', uiSchema, ' path ', path, ' formKind ', formKind, schema);

//                const uiSchemeRequired: boolean = (uiSchema.required !== undefined ? uiSchema.required : false);
//                const schemaRequired: boolean = (schema.required !== undefined ? schema.required : false);
//                const required = schemaRequired || uiSchemeRequired;

                if (formKind !== undefined) {

                    if (formKind === FormKind.ARRAY) {

                        if (schemaElem.items) {
                            if (uiSchema.options !== undefined && uiSchema.options.multi !== undefined && uiSchema.options.multi) {
                                const {label, placeholder} = uiSchema;

                                return <FormItem
                                    error={(errorsMap[path] !== undefined ? errorsMap[path].type : undefined)}
                                    errorMessage={uiSchema.error}
                                    key={key}
                                    path={path}
                                    kind={FormKind.SELECT}
                                    allowClear={get(uiSchema, 'options.alloqClear')}
                                    context={context}
                                    mode={'tags'}
                                    optionValues={['']}
                                    label={label}
                                    useTranslation={useTranslation}
                                    placeholder={placeholder}
                                    help={uiSchema.help}
                                    t={t}
                                    textTransformation={get(uiSchema, 'options.textTransform')}
                                />
                            } else {

                                return <ArrayContainer
                                    key={key + '-'+path}
                                    id={key + '-'+path}
                                    schema={schemaElem.items}
                                    path={path}
                                    uiLayout={layoutMap[path]}
                                    layout={layout}
                                    context={context}
                                />
                            }
                        } else {
                            return <div key={key}></div>
                        }
                    } else {


                    }
                }
                return <div key={key}></div>
            }
            case LayoutKind.VERTICAL:

                return <VerticalLayout
                    className={className}
                    key={`${path}-${key}`}>{getComponents(errorsMap, schema, uiSchema, layout, context, path, useTranslation, handleAction, store, errorsSetter, t, className)}</VerticalLayout>;
            case LayoutKind.CONTROL:

                let formKind = getLayoutFromSchema(path);

                const uiSchemeRequired: boolean = (layoutMap[path]!.required || false);
                const schemaRequired: boolean = (schemaElem!== undefined ? (schemaElem.required  !== undefined ? schemaElem.required : false) : false);
                const required = schemaRequired || uiSchemeRequired;


                if (formKind !== undefined) {

                    // console.log('UIControl: ', path, uiSchema, key);

                    if (formKind === FormKind.ARRAY) {

                        if (schemaElem.items) {
                            if (uiSchema.options !== undefined && uiSchema.options.multi !== undefined && uiSchema.options.multi) {
                                const {label, placeholder} = uiSchema;

                                return <FormItem
                                    error={(errorsMap[path] !== undefined ? errorsMap[path].type : undefined)}
                                    errorMessage={uiSchema.error}
                                    key={key}
                                    path={path}
                                    kind={FormKind.SELECT}
                                    allowClear={get(uiSchema, 'options.alloqClear')}
                                    context={context}
                                    mode={'tags'}
                                    optionValues={['']}
                                    label={label}
                                    useTranslation={useTranslation}
                                    placeholder={placeholder}
                                    help={uiSchema.help}
                                    t={t}
                                    textTransformation={get(uiSchema, 'options.textTransform')}
                                />
                            } else {
                                //   console.log('UILayout: array container ', layoutMap[path], path, schemaElem );
                                return <ArrayContainer
                                    id={key}
                                    schema={schemaElem.items}
                                    path={path}
                                    uiLayout={layoutMap[path]}
                                    layout={layout}
                                    context={context}
                                />
                            }
                        } else {
                            return <div key={key}></div>
                        }
                    } else {


                        if (uiSchema.rule !== undefined) {

                            let res: boolean = true;
//                            let show: boolean = true;


                            if ((uiSchema.rule.condition.or !== undefined)) {
                                res = evaluateOrBoolOperation(uiSchema.rule.condition, store.getData());
                            } else {

                                if ((uiSchema.rule.condition.and !== undefined)) {
                                    res = evaluateAndBoolCondition(uiSchema.rule.condition, store.getData());
                                } else {

                                    if ((uiSchema.rule.condition.not !== undefined)) {
                                        res = evaluateNotCondition(uiSchema.rule.condition, store.getData());
                                    } else {
                                        //      const condValue = get(store.getData(), uiSchema.rule.condition.path);
                                        res = evaluateBasicCondition(uiSchema.rule.condition, store.getData());

                                    }
                                }
                            }

                            switch (uiSchema.rule.effect) {
                                case UIEffect.HIDE:
                                    res = !res;
                                    break;
                                case UIEffect.SHOW:
                                case UIEffect.LEAF:
                                    break;
                            }

                            let optionValues = schemaElem.values;


                            if (schemaElem.type === SchemaType.STRING && uiSchema.values !== undefined && uiSchema.values instanceof Array) {
                                if (optionValues === undefined || (optionValues !== undefined && !(optionValues instanceof Array))) {
                                    optionValues = uiSchema.values;
                                    formKind = FormKind.SELECT;
                                }
                            }


                            return ((res ? <FormItem
                                error={(errorsMap[path] !== undefined ? errorsMap[path].type : undefined)}
                                errorMessage={uiSchema.error}
                                showOnlyBusinessDays={uiSchema.options !== undefined ? uiSchema.options.showOnlyBusinessDays : undefined}
                                key={key}
                                path={path}
                                kind={formKind}
                                allowClear={get(uiSchema, 'options.allowClear')}
                                context={context}
                                asRadioButtons={uiSchema.options !== undefined ? uiSchema.options.asRadioButtons : undefined}
                                label={uiSchema.label}
                                placeholder={uiSchema.placeholder}
                                required={required}
                                help={uiSchema.help}
                                maxLength={get(uiSchema, 'options.maxLength')}
                                useTranslation={useTranslation}
                                optionValues={optionValues}
                                t={t}
                                textTransformation={get(uiSchema, 'options.textTransform')}
                                validator={(schemaElem.format !== undefined ? (data: any) => {
                                    let res = true;

                                    if (schemaElem.format !== undefined) {
                                        res = validatePattern(schemaElem.format, data)
                                    }
                                    return res;
                                } : undefined)}
                            /> : <div key={key}></div>))


                        }
                    }

                    return (
                        <FormItem
                            error={(errorsMap[path] !== undefined ? errorsMap[path].type : undefined)}
                            errorMessage={uiSchema.error}
                            key={key}
                            path={path}
                            showOnlyBusinessDays={uiSchema.options !== undefined ? uiSchema.options.showOnlyBusinessDays : undefined}
                            allowClear={get(uiSchema, 'options.allowClear')}
                            kind={formKind}
                            asRadioButtons={uiSchema.options !== undefined ? uiSchema.options.asRadioButtons : undefined}
                            context={context}
                            label={uiSchema.label}
                            placeholder={uiSchema.placeholder}
                            required={required}
                            help={uiSchema.help}
                            optionValues={schemaElem.values}
                            t={t}
                            useTranslation={useTranslation}
                            textTransformation={get(uiSchema, 'options.textTransform')}
                            maxLength={get(uiSchema, 'options.maxLength')}
                            validator={(schemaElem.format !== undefined ? (data: any) => {
                                let res = true;

                                if (schemaElem.format !== undefined) {
                                    res = validatePattern(schemaElem.format, data)
                                }
                                return res;
                            } : undefined)}
                        />
                    )
                }
                return <></>;
            case LayoutKind.PAPER:
                return <PaperLayout
                    key={path}>{getComponents(errorsMap, schema, uiSchema, layout, context, path, useTranslation, handleAction, store, errorsSetter, t, className)}</PaperLayout>;
            default:
                return <></>;
        }
    } else {
        return undefined;
    }
};


export const buildField = (schema: Schema,
                           path: string,
                           uiPath: string,
                           layout: FormInterface,
                           context: React.Context<IStoreContext>): React.ReactElement<any> => {



    let label = (layoutMap[uiPath] !== undefined ? layoutMap[uiPath].label : undefined);
    let  placeholder = (layoutMap[uiPath] !== undefined ? layoutMap[uiPath].placeholder : undefined);

    const uiLayout = layoutMap[uiPath];

    const tooltip: string | undefined = uiLayout.help;


    switch ( schema.type ) {

        case SchemaType.ENUM: {
            let mode = (uiLayout !== undefined && uiLayout.options !== undefined && uiLayout.options.multi !== undefined  && uiLayout.options.multi ? 'multiple': 'single');
            return  (
                <FormItem
                    errorMessage={uiLayout.error}
                    path={path}
                    kind={FormKind.SELECT}
                    context={context}
                    optionValues={schema.values}
                    label={label}
                    help={tooltip}
                    asRadioButtons={uiLayout.options !== undefined ?uiLayout.options.asRadioButtons : undefined}
                    placeholder={placeholder}
                    mode={mode as (typeof  SelectModeValues[number])}/>
            )
        }

        case SchemaType.OBJECT: {

            let componentArray: Array<React.ReactElement<any>> = [];
            let root = schema.name;
            if (path.length > 0) {
                root = '.'+ root;
            }

            if (schema.properties) {
                for (let prop in schema.properties) {
                    const thePath = path+ root+ '.'+prop;
                    const theUIPath = uiPath+ root+ '.'+prop;
                    //       console.log('thePath: ', thePath, ' uiPath ', theUIPath, schema, layout);
                    componentArray.push(buildField(schema.properties[prop], thePath, theUIPath, layout, context));
                }
            }
            //return <>{componentArray}</>;
            return buildLayout(layoutMap[uiPath+root], componentArray);
        }

        case SchemaType.STRING:
            let thePath = path+(schema.name !== undefined ? '.'+schema.name : '');

            if (label === undefined && uiPath !== thePath) {
                let uiP = uiPath + (schema.name !== undefined ? '.' + schema.name : '');
                let lM = layoutMap[uiP];
                label = (lM !== undefined ? lM.label : undefined);
                placeholder = (lM !== undefined ? lM.placeholder : undefined);
            }


           // console.log('PLACEHOLDER... '+placeholder, ' :> ',uiLayout.options)


            return (
                <FormItem
                    errorMessage={uiLayout.error}
                    path={thePath}
                    kind={FormKind.TEXT}
                    context={context}
                    required={schema.required}
                    label={label}
                    help={tooltip}
                    placeholder={placeholder}
                    maxLength={uiLayout?.options?.maxLength}
                    textTransformation={(uiLayout.options !== undefined ? uiLayout.options.textTransform : undefined)}
                />
            );

        case SchemaType.TEXTAREA:
            return <FormItem errorMessage={uiLayout.error} path={path} kind={FormKind.TEXTAREA} context={context} required={schema.required} label={label} placeholder={placeholder} help={tooltip}/>;
        case SchemaType.INTEGER:
        case SchemaType.NUMBER:

            return <FormItem errorMessage={uiLayout.error} path={path} kind={FormKind.NUMBER} context={context} required={schema.required} label={label} placeholder={placeholder} help={tooltip}/>;

        case SchemaType.BOOLEAN:
            return <FormItem errorMessage={uiLayout.error} path={path} kind={FormKind.CHECKBOX} context={context} required={schema.required} help={tooltip}/>
        case SchemaType.ARRAY:
            //  console.log('buildField: SchemaType: ARRAY', schema);
//            let root = schema.name;
            let arrayController = null;
            if (schema.items) {
                // let innerComponent = buildField(schema.items,  path + '.$', layout, context);
                if (uiLayout !== undefined && uiLayout.options !== undefined && uiLayout.options.multi) {
                    arrayController =
                        <FormItem errorMessage={uiLayout.error} path={path} kind={FormKind.SELECT} context={context} mode={'tags'} optionValues={['']} label={label} placeholder={placeholder} help={tooltip}/>
                } else {
                    //     console.log('ArrayContainer: schema: ', schema.items, ' path ', path, ' uiPath ', uiPath, ' uiLayout ', layoutMap[uiPath])
                    arrayController =
                        <ArrayContainer
                            id={path}
                            schema={schema.items}
                            path={path}
                            uiLayout={layoutMap[uiPath]}
                            layout={layout}
                            context={context}
                        />
                }
            }
            return (<>{arrayController}</>)

    }
};


const showErrors = (errors: Array<FormError>) => {
    let errorsComponent: Array<React.ReactElement<any>>  =  [];
    errors.forEach((error, idx) => {
        // console.log('show Error: ', error);
        // console.log('layputMap ', layoutMap,  ' -> ', layoutMap[error.path], ' errorPath: ', error.path);

        const missing: boolean = layoutMap[error.path] === undefined;

        const label = !missing ? layoutMap[error.path].label : error.path;

        errorsComponent.push(
            <HorizontalLayout key={idx} style={{width: '100%'}}>
                <Flex direction={'row'} justify={'center'} align={'center'}>
                    <FontAwesomeIcon  color={'#f5222d'} icon={faExclamationTriangle}/>
                    <div style={{paddingRight: '10px', paddingLeft: '10px', fontWeight: 600}}>Errore</div>
                </Flex>
                <div style={{fontWeight: 600}}>{label}</div>
                <div> {error.type} {missing? ' è mancante' : ''}</div>
            </HorizontalLayout>
        )
    });
    return (
        <PaperLayout style={{width: '60%',  border: '1.3px solid #f5222d'}}>
            <VerticalLayout>
                {errorsComponent}
            </VerticalLayout>
        </PaperLayout>
    )

};


const Form: React.FC<FormInterface> = (props): React.ReactElement<any> => {
//    let itemsTable: Map<React.ReactElement<any>> = {} as Map<React.ReactElement<any>>;
    const [errors, setErrors] = React.useState([] as Array<FormError>);
    const [errorMap, setErrorMap] = React.useState({} as Map<string>);

    const store = React.useContext(StoreContext);
    const t = props.t ?? fooT;

    React.useEffect(()=> {

        if (props.errors !== undefined) {
            setErrors(props.errors);
        }
    }, [props.errors]);

    /*
        const findLayoutForPath = (path: string, layout: FormLayout): FormLayout => {
            let subLayuut: FormLayout = {} as FormLayout;
            return subLayuut;
        }
    */

    const getCustomizedLayout = (layout: Layout, itemsTable: Map<React.ReactElement<any>>, key: string | number) => {
        switch(layout.kind) {
            case LayoutKind.VERTICAL:
                if (layout.elements) {
                    let components = new Array<React.ReactElement<any>>();
                    for (let e = 0; e < layout.elements.length; e++) {
                        const component = getCustomizedLayout(layout.elements[e], itemsTable, e); // eslint-disable-line @typescript-eslint/no-unused-vars

                        if (component)
                            components.push(component);
                    }
                    return (<VerticalLayout key={key}>{components}</VerticalLayout>)
                }

                break;
            case LayoutKind.CONTROL:
                if (layout.path) {
                    return (itemsTable[layout.path]);
                }
                break;
            case LayoutKind.HORIZONTAL:
                if (layout.elements) {
                    let components = new Array<React.ReactElement<any>>();
                    for (let k = 0; k < layout.elements.length; k++) {

                        const component = getCustomizedLayout(layout.elements[k], itemsTable, k); // eslint-disable-line @typescript-eslint/no-unused-vars

                        if (component) {
                            components.push(component);
                        }
                    }
                    return (<HorizontalLayout key={key}>{components}</HorizontalLayout>)
                }

                break;
            case LayoutKind.GROUP:
                return <></>;
            case LayoutKind.ARRAY:
                return <></>;
            case LayoutKind.PAPER:
                return <></>;
            default:
                return <></>;
        }
    };


    const resetValidator = () => {
        setErrors([]);
        setErrorMap({} as Map<string>);
    };

    const forceErrors = (errors: Map<string>): any => {
        setErrorMap(errors);
        return errors;
    };

    const handleValidate = (t:(key:string)=>string): any => {
        let convertedErrors = {} as Map<string>;

        if (errors.length > 0) {

            errors.forEach((error) => {
                convertedErrors[error.path] = error.type;
            });

            setErrorMap(convertedErrors);
            setErrorMap(convertedErrors);
        }

        return convertedErrors;
    };

    //let bField = buildField(props.schema, '', '', props, props.context);

    // console.log('Schema: ', props.schema , ' UISchena: ', props.uischema);

    layoutMap = {}  as Map<UILayout>;

    visitUILayout(props.schema, props.uischema, store, layoutMap);

    visitSchema('',props.schema);

    const errorsMap: Map<FormError> = {};

    errors.forEach((error)=> {
        errorsMap[error.path] = error;
        errorMap[error.path] = error.type;
    });

    // console.log('FORM -> useTranslation: ', props.useTranslation);

    const _className = props.className ?? '';

    let bField =
        buildFieldFromUISchema(_className, errorsMap, props.schema, '', props.uischema, props, props.context, props.useTranslation !== undefined ? props.useTranslation : true, props.handleAction, store, setErrors, t);

    const okButtonDisabled: boolean = (props.okButtonDisabled ?? false);

    return (
        <>
        {errors && errors.length > 0 && <Flex direction={'row'} style={{width: '100%'}}>{showErrors(errors)}</Flex>}
        <ValidateContext.Provider
            value={{
                handleValidate: () => {handleValidate(t);},
                forceErrors: (errors:Map<string> ) => {forceErrors(errors);},
                errors: errorMap,
                resetValidation:()=> {resetValidator();}
            }}
        >
            <BackTop className={'back-top'}/>
            <div className="ant-form ant-form-vertical">
                {bField}
            </div>
        </ValidateContext.Provider>
        {(props.showButtons === undefined || (props.showButtons !== undefined && props.showButtons)) &&
        <HorizontalLayout>

            <Button
                onClick={() => {
                    props.onReset();
                }}
            >
                {props.resetButton !== undefined ? props.resetButton : 'Annulla'}
            </Button>
            {props.clean !== undefined &&
            <Tooltip title={'Cancella i dati'}>
                <Button shape={'circle'} type={'primary'}
                        onClick={() => {
                            if (props.clean !== undefined) {
                                props.clean();
                            }
                        }}
                >
                    <FontAwesomeIcon icon={faBroom}/>
                </Button>
            </Tooltip>
            }

            <Button
                type={'primary'}
                disabled={okButtonDisabled}
                loading={props.loading}
                onClick={() => {


                    let errors: Array<FormError> = [];

                    if (props.validate !== undefined) {
                        errors = errors.concat(props.validate(store.getData()));
                    }


                    if (errors.length === 0) {
                        const schemaErrors: Array<FormError> = validate('', props.schema, store.getData(), layoutMap);

                        errors = errors.concat(schemaErrors);
                    }

                    if (errors.length > 0) {
                        setErrors(errors);
                        handleValidate(t);
                    } else {
                        // console.log('No Error');
                        props.handleAction(store.getData());
                    }
                }}>{props.okButton !== undefined ? props.okButton : 'Salva'}</Button>
        </HorizontalLayout>
        }
        </>
    )
};

export default Form;