import React from 'react';
import {notification} from 'antd'
import './App.css';
import {ProtectedRoute, Switch} from "./router/Router";
import {Navigate, Route} from "react-router";
import {LayoutManager} from "./context/LayoutContext";
//import layoutConf from './configuration/LayoutConfiguration'
import layoutConf from './configuration/LayoutConfiguration/test'
import {Spinner} from "./components/Spinner";
import MainWizard from "./controller/MainWizard";
import {Store} from "./context/FormContext";
import Breadcrumb from "./layout/Breadcrumb";
import Header from "./layout/Header";
import RiepilogoFinale from "./components/RiepilogoFinale";
import Footer from "./layout/Footer";
import {VALIDATED_PATH, VERIFY_PATH} from "./configuration/index";
import WrongPath from "./components/WrongPath";

/*

interface IUserInfo {
    givenName: string
    familyName: string
    companyName?: string
    email: string
    fiscalCode: string
}





function getUserInfoFromToken(keycloak: KeycloakInstance) {
    console.log('Instance: ', keycloak);
    const { tokenParsed } = keycloak;
    return  (
        {
            givenName: tokenParsed?.given_name,
            email: tokenParsed?.email,
            fiscalCode: tokenParsed?.ficalCode,
            familyName: tokenParsed?.family_name
        } as IUserInfo
    )
}
*/

function App() {
    notification.config({
        duration: 8,
    });

    return (
        <LayoutManager
            layoutConf={layoutConf}
            header={<Header title={'Procedura di validazione del Registrante per domini gov'}/>}
            footer={<Footer/>}
            breadcrumb={<Breadcrumb/>}
            body={
                <Store data={undefined} debug={false}>
                <Switch>

                    <Route path={'/'} element={<Navigate to={VERIFY_PATH}/>}/>

                    <Route
                        path={VERIFY_PATH}
                        element={
                            <ProtectedRoute
                                fallback={<Spinner/>}
                                error={<span>Session Expired</span>}
                            >
                                <Store data={undefined} debug={false}>
                                    <MainWizard loading={false}/>
                                </Store>
                            </ProtectedRoute>
                        }
                    >

                    </Route>


                    <Route
                        path={VALIDATED_PATH}
                        element={

                                <RiepilogoFinale/>
                        }
                    >

                    </Route>

                    <Route
                        path={'*'}
                        element={<WrongPath asCard={true}/>}
                    />

                </Switch>
                </Store>
            }
        />

    );
}

export default App;
