import React from 'react'
import {Checkbox} from 'antd'
import get from 'lodash/get'
import {StoreContext} from "../context/FormContext";
import {CHECK_UO, OP_COMPLETA} from "../configuration";
import {Flex} from "@nic/ui-comps";


interface UOCheckboxI {
}

const UOCheckbox: React.FC<UOCheckboxI> = (props) => {
    const store = React.useContext(StoreContext)
    const _data = store.getData();
    const _checked = get(_data, CHECK_UO)
    const [selected, setSelected] = React.useState<boolean>(_checked ?? false)

    const onChange = (e: any) => {

        const _checked: boolean = e.target.checked as boolean
        setSelected(_checked);
        store.handleUpdate(CHECK_UO, _checked)
        if (!_checked) {
            store.handleUpdate(OP_COMPLETA, undefined)
        }
    }

    return (
        <Flex direction={'row'} justify={'flex start'} style={{marginTop: '20px', marginBottom: '20px'}}>
            <Checkbox  checked={selected} onChange={onChange}>Seleziona Unità Organizzativa</Checkbox>
        </Flex>
    )
}

export default UOCheckbox
