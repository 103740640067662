import React from 'react'
import {Input, Modal, Select, Table} from 'antd'
import {CloseCircleOutlined} from '@ant-design/icons';
import get from 'lodash/get'
import {Div, Flex, VerticalLayout} from "@nic/ui-comps";
import {useResource} from 'rest-hooks';
import EsistenzaAmministrazione from "../api/EsistenzaAmministrazione";
import UnitaOperative from "../api/UnitaOperative";
import {ColumnBuilder, DatasourceBuilder} from "../utils";
import {StoreContext} from "../context/FormContext";
import '../components/SelectedTable.css'
import TitleCloseable from "../components/TitleCloseable";
import UnitaOperativaViewer from "../components/UnitaOperativaViewer";
import {OP_COMPLETA, SELECTED_PEC} from "../configuration/index";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface SelectUOI {
    amministrazionePath: string,
    uoPath: string
    //onChanged: (uos: any) => void
}


type ISimpleRenderFunction = (args: any) => React.ReactElement<any>
type IFullRenderFunction = (text: any, record: any, index: any) => React.ReactElement<any>

type IRenderFunc = ISimpleRenderFunction | IFullRenderFunction


const translation: Record<string, string> = {
    codiceUO: 'Codice Unità Organizzativa',
    denominazione: 'Denominazione',
    codiceFiscale: 'Codice Fiscale',
    cap: 'CAP',
    address: 'Indirizzo',

};

/*
const sercheable: Record<string, boolean> = {
    codiceUO: true,
    denominazione: true,
    codiceFiscale: false,
    cap: false,
    address: false,

};


const INITIAL_STATE = {
    codiceUO: false,
    denominazione: false
}
*/

const filterMapping: Record<string, string> = {
    codiceUO: 'ipa.filterUO',
    denominazione: 'ipa.filterDenominazione'
}



const SelectUO: React.FC<SelectUOI> = (props) => {

    const store = React.useContext(StoreContext);

    const _data = store.getData();

    const _selectedUO = get(_data, OP_COMPLETA)

    const _filterUO = get(_data, 'ipa.filterUO')
    const _filterDenominazione = get(_data, 'ipa.filterDenominazione')
    const _filterKind = (_filterUO === undefined && _filterDenominazione === undefined ? 'None' : (_filterUO !== undefined ? 'codiceUO' : 'denominazione'))

    const [value, setValue] = React.useState<string>("")
    const [valueDisabled, setValueDisabled] = React.useState<boolean>(_filterKind === 'None')
    const [filterKind, setFilterKind] = React.useState<string>(_filterKind)
    const [filter, setFilter] = React.useState<Record<string, string> | undefined>(undefined)
    const [isVisible, setIsVisible] = React.useState(false)
    //const [UOComponent, setUOComponent] = React.useState<React.ReactElement<any> | undefined>(undefined)

    const [uoSelected, setUoSelected] = React.useState<UnitaOperative | undefined>(_selectedUO)

    const [selectedRow, setSelectedRow] = React.useState<number| undefined>(undefined)

    const {amministrazionePath} = props;

    const amministrazione:EsistenzaAmministrazione =
        get(store.getData(), amministrazionePath)

    const [pagination, setPagination] = React.useState({page: 0, size: 10})
    const columns = new ColumnBuilder()
        .setColumn('codiceUO')
        .setColumn('denominazione')
        .setColumn('address')
        .build();


    React.useEffect(() => {
        setFilterKind(_filterKind)
        setValueDisabled(_filterKind === 'None')
    }, [_filterKind])


    const uos4Ipa = useResource(
        UnitaOperative.list(),
        {
            codiceIpa: amministrazione.codiceIpa,
            ...filter,
            page: pagination.page,
            size: pagination.size
        }
    )

/*
    const handleSearch = (confirm: any, selectedKeys: any, dataIndex: any) => {
        confirm()
        const _filter: Record<string, string> = {}

        _filter[dataIndex] = selectedKeys
        setFilter(_filter)
        //       setFilterDropdownVisible(INITIAL_STATE)
    }

    const handleReset = (clearFilters: any, dataIndex: any) => {

        setFilter(undefined)
        //clearFilters()
    }
*/
/*
    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters}: any) => (
            <div style={{ padding: 8 }}>
                <Input

                    placeholder={`Cerca ${translation[dataIndex]}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch( confirm, selectedKeys,dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>

                    <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => handleSearch( confirm, selectedKeys, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Cerca
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: any) => <SearchOutlined style={{ fontSize: '1rem', color: filtered ? 'red' : '#1890ff' }} />,
        onFilter: (value: any, record: any) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible: any) => {
        },
    });

*/


    const renderConf: Record<string, IRenderFunc> = {
        address: (text: any, record: any, index: any) => {
            return (
                <Flex direction={'row'} justify={'flex-start'} style={{gap: 5}}>
                    <div>{record.address.indirizzo}</div>
                    <div>{record.address.cap}</div>
                    <div>{record.address.comune}</div>
                    <div>{record.address.provincia}</div>
                </Flex>
            )
        },
    }

    const buildColumns = (_view: string[], render?: Record<string, IRenderFunc>) => {
        let res: Record<string, any>[] = []
        _view.forEach((v) => {
            const searchOptions = {}
            //(sercheable[v] ? getColumnSearchProps(v) : {})

            res.push({
                title: `${translation[v]}`,
                dataIndex: v,
                key: v,
                render: render !== undefined ? render[v] : undefined,
                ...searchOptions
            })

        })
        return res
    }


    const dataset =
        new DatasourceBuilder<UnitaOperative>(uos4Ipa.elements, columns).builsDataSource()


    const page: any = uos4Ipa.page;

    // pagination={{current: uos4Ipa.page.current, pageSize: uos4Ipa.page.}}
    // number: 0, size: 20, totalElements: 365, totalPages: 19
    const _pagination = {
        current: page.number+1,
        pageSize: page.size,
        total: page.totalElements
    }

    const onOk = () => {

        setIsVisible(false)
    }

    const resetFilters = () => {
        store.handleUpdate('ipa.filterUO', undefined)
        store.handleUpdate('ipa.filterDenominazione', undefined)
    }

    const onCancel = () => {
        setIsVisible(false)
        store.handleUpdate(OP_COMPLETA, undefined)
        //setUOComponent(undefined)
        setUoSelected(undefined)
    }

    const rowClassNameRenderer = (record:any, index: any) => {

        if (selectedRow === index) {
            return 'pointer selected';
        } else {
            return 'pointer';
        }
    }

    let component = <></>
    if (uoSelected !== undefined && !isVisible) {
        component = (
            <TitleCloseable
                label={'Unità Organizzativa Selezionata'}
                action={() => {
                    setUoSelected(undefined)
                    store.handleUpdate(OP_COMPLETA, undefined)
                    store.handleUpdate(SELECTED_PEC, undefined)
                    resetFilters()
                }}
            >
                <UnitaOperativaViewer uo={uoSelected}/>
            </TitleCloseable>
        )
    } else {
        if ( uoSelected === undefined || isVisible ) {

            component = (
                <VerticalLayout style={{gap: 20}}>

                    <Flex
                        direction={'row'}
                        justify={'space-between'}
                        className={'info-box'}
                        style={{gap: 40}}
                    >
                        <Flex direction={'row'} justify={'flex-start'} style={{gap: 40}}>
                            <Div fontWeight={'700'}>Cerca:</Div>
                                <Flex direction={'row'} justify={'flex-start'} style={{gap: 10}}>
                                    <Div>Campo</Div>
                                    <Select
                                        value={filterKind}
                                        onChange={(value) => {
                                            setFilterKind(value)
                                            setValueDisabled(value==='None')
                                            if (value === 'None') {
                                                setFilter(undefined)
                                                setValue('')
                                            }
                                        }}
                                    >
                                        <Select.Option value={'None'}>Nessuna Selezione</Select.Option>
                                        <Select.Option value={'codiceUO'}>{translation['codiceUO']}</Select.Option>
                                        <Select.Option value={'denominazione'}>{translation['denominazione']}</Select.Option>
                                    </Select>
                                    {!valueDisabled &&
                                        <>
                                            <Div>Valore</Div>
                                            <Input
                                                onChange={e => setValue(e.target.value)}
                                                onPressEnter={() => {
                                                    if (value.length > 0) {
                                                        const _filter: Record<string, string> = {}
                                                        resetFilters()
                                                        _filter[filterKind] = value
                                                        store.handleUpdate(filterMapping[filterKind], value)
                                                        setFilter(_filter)
                                                    }
                                                }}
                                                value={value}
                                            />
                                            <FontAwesomeIcon
                                                style={{cursor: 'pointer'}}
                                                icon={faSearch}

                                                onClick={() => {
                                                    if (value.length > 0) {
                                                        const _filter: Record<string, string> = {}
                                                        resetFilters()
                                                        _filter[filterKind] = value
                                                        store.handleUpdate(filterMapping[filterKind], value)
                                                        setFilter(_filter)
                                                    }
                                                }}
                                            />

                                        </>
                                    }
                                </Flex>

                        </Flex>
                        <Flex direction={'row'} justify={'flex-start'} style={{gap: 10}}>
                            <Div>Annulla</Div>
                            <Div
                                style={{cursor: 'pointer'}}
                                onClick={()=> {

                                    setFilter(undefined);
                                    setValue('')
                                    //setFilterKind('None')
                                    resetFilters()
                                }}>
                                <CloseCircleOutlined />
                            </Div>
                        </Flex>
                    </Flex>

                    <Table
                        className={'gov'}
                        size={'small'}
                        dataSource={dataset}
                        style={{width: '100%'}}
                        columns={buildColumns(columns, renderConf)}
                        pagination={_pagination}
                        onChange={({current, pageSize, total}) => {
                            setPagination({page: (current !== undefined ? current - 1 : 0), size: pageSize ?? 20})
                        }}
                        rowClassName={rowClassNameRenderer}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => {
                                    setSelectedRow(rowIndex)
                                    //console.log('Selected: ',    record, rowIndex)
                                    const {elements} = uos4Ipa
                                    //console.log('Selected ', elements[rowIndex??0])
                                    const selected = elements[rowIndex??0];
                                    setUoSelected(selected)
                                    store.handleUpdate(OP_COMPLETA, selected)
                                    //const {codiceUO} = record
                                    setIsVisible(true)
                                }, // click row
                            };
                        }}

                    />
                </VerticalLayout>
            )
        }
    }




    return (
        <Flex direction={'row'} width={'100%'}>
            <Modal
                title={<Div fontSize={'1.2rem'} fontWeight={'700'}>Seleziona Unità Organizzativa</Div>}
                visible={isVisible}
                onOk={onOk} onCancel={onCancel}
                width={'80vw'}
                okText={'Seleziona'}
                cancelText={'Annulla'}
                closable={false}
                className={'gov-buttons'}
                wrapClassName={'gov-buttons'}
            >

                <UnitaOperativaViewer uo={uoSelected}/>

            </Modal>
            {component}
        </Flex>
    )
}

export default SelectUO
