export class ColumnBuilder {
    private _innerColums: string[] = []

    constructor() {
        this._innerColums = []
    }

    setColumn(name: string, condition?: boolean) {
        if (condition === undefined || (condition !== undefined && condition)) {
            this._innerColums.push(name)
        }
        return this
    }

    build() {
        return this._innerColums
    }
}

