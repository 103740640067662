import {LayoutBuilderElement} from "../../context/LayoutContext";

export default {
    element: 'vertical',
    options: { gap: 10,   height:'100vh', flexWrap: 'nowrap', alignContent: 'space-between'},
    elements: [


        {element: 'vertical',
            elements: [

                {

                    element: 'header',
                    options: {

                        backgroundColor: '#003183',
                        alignItems: 'center',
                        background: '#003183',
                        fontSize: '1.5rem',
                        color: 'white',
                        padding: '10px'
                    },

                },
                {

                    element: 'breadcrumb',
                    options: {

                        backgroundColor: 'black',
                        background: '#0059b3',
                        color: 'white',
                        padding: '5px',
                    },

                },

            ]
        },

                {
                    element: 'body',
                    options: {

                        minWidth: '70%',

                        width: '90%',
                        alignSelf: 'center'
                    },
                },


        {
            element: 'footer',
            options: {
                marginTop: 'auto',
                width: '100vw',
                borderTop: '3px solid lightgray',
                backgroundColor: 'white',
                color: 'black'
            },
        },
    ],
} as LayoutBuilderElement