import React from 'react';
import {Div, Flex, PaperLayout} from "@nic/ui-comps";

import {InfoCircleTwoTone, WarningTwoTone} from '@ant-design/icons'

interface DescriptionInterface {
    description?: string | React.ReactElement;
    fontSize?: string;
    fontWeight?: string;
    expanded?: boolean;
    kind?: string;
}

const DEFAULT_FONT_SIZE = '11pt';
const DEFAULT_FONT_WEIGHT = '200';
//const COLOR = '#1890ff';
//<InfoCircleOutlined />
//<FontAwesomeIcon icon={faInfoCircle}/>

interface IconColorInterface {
    icon: React.ReactElement;
    color: string;
}

const IconColorMap: Record<string, IconColorInterface> = {
  info: {icon: <InfoCircleTwoTone twoToneColor={'#1890ff'}/>, color: '#1890ff'},
  warning:  {icon: <WarningTwoTone twoToneColor={'orange'}/>, color: 'orange'},
};

const Description: React.FC<DescriptionInterface> = (props) => {
    let component = <></>;
    const [expanded, setExpanded] = React.useState(props.expanded ?? true);

    const kind = props.kind ?? 'info';

    const {icon} = IconColorMap[kind];

    if (props.description !== undefined) {
        if (expanded) {
            component = (
                <PaperLayout
                    asCard={false}
                    padding={'5px'}
                    marginTop={'5px'}
                    marginBottom={'10px'}
                    backgroundColor={'rgba(240, 240, 240, 0.7)'}
                    style={{borderColor: 'rgba(240, 240, 240, 1)'}}
                >

                    <Flex
                        direction={'row'}
                        style={{
                            width: '100%',
                            fontSize: (props.fontSize !== undefined ? props.fontSize : DEFAULT_FONT_SIZE)
                        }}
                        justify={'flex-start'}
                    >

                        <Div
                            fontSize={'2em'}
                            color={'#1890ff'}
                            marginRight={'10px'}
                            marginLeft={'5px'}
                            onClick={()=>{setExpanded(false)}}
                        >
                            {icon}

                        </Div>
                        <Div
                            fontSize={'1em'}
                            textAlign={'left'}
                            fontWeight={(props.fontWeight !== undefined ? props.fontWeight : DEFAULT_FONT_WEIGHT)}
                        >
                            {props.description}
                        </Div>
                    </Flex>
                </PaperLayout>
            )
        } else {
            component = (
                <Flex
                    direction={'row'}
                    style={{
                        width: '100%',
                        fontSize: (props.fontSize !== undefined ? props.fontSize : DEFAULT_FONT_SIZE)
                    }}
                    justify={'flex-start'}
                >
                    <Div
                        fontSize={'1.5em'}
                        color={'#1890ff'}
                        marginRight={'10px'}
                        marginLeft={'5px'}
                        onClick={()=>{setExpanded(true)}}
                    >
                        <InfoCircleTwoTone twoToneColor={'#1890ff'}/>

                    </Div>

                </Flex>
            )
        }
    }
    return (
        <>
        {component}
        </>
    )
};

export default Description;