
type Pick<T, U extends keyof T> = { [K in U]: T[K] }

function pick<T, U extends keyof T>(obj: T, keys: U[]): Pick<T, U> {
    // eslint-disable-next-line no-sequences
    return keys.reduce((acum: any, key: keyof T) => ((acum[key] = obj[key]), acum), {} as T)
}

export class DatasourceBuilder<T> {
    private _datasource: any[] = []
    private _data: T[] = []
    private _cols: string[] = []
    constructor(data: T[], view?:string[]) {
        this._data = data;
        this._datasource = []

        this._cols = view ?? (data.length === 0 ? [] : Object.keys(data[0]));

    }

    builsDataSource() {
        let keys = this._cols as (keyof T)[]
        type Keys = typeof keys[number]
        type Selected = Pick<T, Keys>
        let res: any[] = []
        this._data.forEach((d: Selected, index: number) => {
            res.push({
                key: `${index}`,
                ...pick(d, keys),
            })
        })
        return res
    }
}
