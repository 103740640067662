import React from 'react'
import EsistenzaAmministrazione from "../api/EsistenzaAmministrazione";
import {Div, Flex, PaperLayout, VerticalLayout} from "@nic/ui-comps";
import Address from "../api/Address";


interface IAmministrazioneViewer {
    amministrazione: EsistenzaAmministrazione
    view: 'minimal' | 'full'
    direction: 'row' | 'column'
}


const amminstrazioneStyle: Record<string, React.CSSProperties> = {
    'true':{
        border: '1px solid',
    //    borderColor: '#b7eb8f',
      //  background: '#f6ffed',
        //color:'#389e0d',
        borderRadius: '5px',
        padding: '20px',
        gap: 10
    },
    'false': {
        border: '1px solid',
        //color:'#cf1322',
       // background: '#fff1f0',
        //borderColor: '#ffa39e',
        padding: '20px',
        borderRadius: '5px',
        gap: 10
    }
}

function address(address: Address) {
    return (
        <Flex direction={'row'} justify={'flex-start'} style={{gap: 5}}>
        <Div fontWeight={'700'}>{address.indirizzo}</Div>

            <Div fontWeight={'700'}>{address.cap}</Div>
            <Div fontWeight={'700'}>{address.comune}</Div>
            <Div fontWeight={'700'}>{address.provincia}</Div>
        </Flex>
    )
}

function field(label: string, value: string) {
    return (
        <Flex direction={'column'} align={'flex-start'}>
            <Div>{`${label}:`}</Div>
            <Div fontWeight={'700'}>{value}</Div>
        </Flex>
    )
}


function  full(amministrazione: EsistenzaAmministrazione) {
    return (
        <VerticalLayout className={amministrazione.enabled ? 'info-box' : 'error-box'} style={amminstrazioneStyle[`${amministrazione.enabled}`]}>

                {field('Codice IPA', amministrazione.codiceIpa)}
                {field('Denominazione', amministrazione.denominazione)}

                <Flex direction={'column'} justify={'flex-start'} align={'flex-start'}>
                    <Div>Indirizzo:</Div>
                    {address(amministrazione.address)}
                </Flex>
                {field('Codice Fiscale', amministrazione.codiceFiscale)}

                {field('Abilitato gov.it', amministrazione.enabled ? 'Si': 'No')}

        </VerticalLayout>
    )
}



const AmministrazioneViewer: React.FC<IAmministrazioneViewer> = (props) => {
    const {amministrazione, view} = props

    if (view === 'full') {
        return full(amministrazione)
    }

    return (
        <PaperLayout asCard={false} className={amministrazione.enabled?'info-box' : 'error-box'}>
        <VerticalLayout style={{gap: 10}}>
            {field('Codice IPA', amministrazione.codiceIpa)}
            {field('Denominazione', amministrazione.denominazione)}


        </VerticalLayout>
        </PaperLayout>
    )
}

export default AmministrazioneViewer