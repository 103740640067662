import React from 'react'
import get from 'lodash/get'
import {Checkbox} from 'antd'
import {Div, Flex, PaperLayout} from "@nic/ui-comps"
import {StoreContext} from "../context/FormContext"
import {ValidateContext} from "../modules/form/context/ValidateContext";

interface BoxedCheckboxI {
    className: string
    path: string
    label: string
    msg?: string | React.ReactNode
}

const BoxedCheckbox: React.FC<BoxedCheckboxI> = (props) => {
    const {path, label, className} = props;
    const validate = React.useContext(ValidateContext);
    const _store = React.useContext(StoreContext);
    const _checked = get(_store.getData(), path);
    const [selected, setSelected] = React.useState<boolean>(_checked ?? false);

    const errorMessage = get(validate.errors, path);
    const error =  errorMessage !== undefined;

    const _classname = error ? 'error-box' : className;

    const onChange = (e: any) => {

        const _checked: boolean = e.target.checked as boolean
        setSelected(_checked);
        _store.handleUpdate(path, _checked)
    };



    return (
        <PaperLayout asCard={false} className={_classname}>
            <Flex style={{gap: 20}} direction={'column'} justify={'flex-start'} align={'flex-start'}>
                <Flex style={{gap: 5}} direction={'column'} justify={'flex-start'} align={'flex-start'}>
                    <Div style={{textAlign: 'justify'}} fontWeight={'700'}>{label}</Div>
                </Flex>
                <Checkbox  checked={selected} onChange={onChange}/>
            </Flex>
            <Div style={{textAlign: 'justify'}} fontWeight={'400'} fontSize={'0.9rem'} className={'error-msg'}>{errorMessage}</Div>
        </PaperLayout>
    )
};
export default BoxedCheckbox