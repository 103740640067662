import React from 'react'
import get from 'lodash/get'
import {VerticalLayout} from "@nic/ui-comps";
import {StoreContext} from "../context/FormContext";
import {EXISTING_VALIDATION, PUBTEST_WARNING, SELECTED_DATA, SELECTED_PEC} from "../configuration/index";
import AmministrazioniValidate from "../api/AmministrazioniValidate";
import {addressField, field} from "../utils/FieldUtils";
import WarningInfo from "./WarningInfo";
import Utils from "../api/Utils";
import {useConfiguration} from "../utils";

interface GovSummaryI {}

/*
function fieldValue(label: string, value: string) {
    return (
        <Flex direction={'row'} justify={'flex-start'} style={{gap: 10}}>
            <Div>{`${label}:`}</Div>

            <Div fontWeight={'700'}>{value}</Div>
        </Flex>
    )
}
*/

function selectedPec(completeDATA: AmministrazioniValidate,
                     selectedPec?:string) {
    return selectedPec ?? completeDATA.pecs[0]
}

// msg={<Div fontWeight={'800'}>ATTENZIONE: </Div>}


const GovSummary: React.FC<GovSummaryI> = (props) => {
    const store = React.useContext(StoreContext)
    const selectedDATA = get(store.getData(), SELECTED_DATA)
    const selectedPEC = selectedPec(selectedDATA, get(store.getData(), SELECTED_PEC))
    const configuration = useConfiguration();

    //console.log('SELECTED_DATA: ', selectedDATA)



    return (
        <VerticalLayout>


        <WarningInfo msg={EXISTING_VALIDATION} rendered={selectedDATA.exists}/>
        <WarningInfo msg={PUBTEST_WARNING} rendered={Utils.isPubTest(configuration)} />
        <VerticalLayout
            className={'info-box'}
            style={{
                gap: 20,
                border: '1px solid',
                padding: '20px',
                borderRadius: '5px'
            }}
        >
            {field('Codice IPA', selectedDATA.codiceIpa)}
            {selectedDATA.codiceUO && field('Codice Unità Organizzativa', selectedDATA.codiceUO)}
            {selectedDATA.codiceFiscale && field('Codice Fiscale', selectedDATA.codiceFiscale)}
            {field('Denominazione', selectedDATA.denominazione)}
            {field('Pec', selectedPEC)}
            {addressField('Indirizzo', selectedDATA.address.indirizzo, selectedDATA.address.cap, selectedDATA.address.comune, selectedDATA.provincia)}

        </VerticalLayout>
        </VerticalLayout>
    )
}

export default GovSummary
