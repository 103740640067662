import React from 'react'
import get from 'lodash/get'
import {useResource} from 'rest-hooks';
import {StoreContext} from "../context/FormContext";
import AmministrazioniValidate from "../api/AmministrazioniValidate";
import OrgView from '../components/OrgView'
import {CONFIRM_DATA, SELECTED_DATA, SELECTED_PEC} from "../configuration/index";
import {emptyData} from "../utils/FieldUtils";

interface DataValidationI {
    ammPath: string
    uoPath: string
}

const DataValidation: React.FC<DataValidationI> = (props) => {
    const {ammPath, uoPath} = props
    const _store = React.useContext(StoreContext)
    const data = _store.getData()
    const amm = get(data, ammPath)
    const uo = get(data, uoPath)
    const selectedPec = get(data, SELECTED_PEC)

    const params: Record<string, string> = {}
    let kind: 'amministrazione' | 'uo' = 'amministrazione'

    params['codiceIpa'] = amm.codiceIpa;

    if (uo !== undefined ) {
        kind = 'uo'
        params['codiceUO'] = uo.codiceUO
    }

    const ammToValidate: AmministrazioniValidate =
        useResource(
            AmministrazioniValidate.detail(),
            {...params})


    React.useEffect(() => {
        if (ammToValidate !== undefined) {
            _store.handleUpdate(SELECTED_DATA, ammToValidate)
        }
        //store.handleUpdate(SELECTED_PEC, undefined)
        _store.handleUpdate(CONFIRM_DATA, false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ammToValidate])


    const selectHandler = (pec: string) => {
        _store.handleUpdate(SELECTED_PEC, pec)
    }

    // console.log('DataValidation: ', emptyData(ammToValidate))

    let component = emptyData(ammToValidate) ?
        <></> :
        <OrgView
            ammData={ammToValidate}
            kind={kind}
            viewUO={true}
            selectedValue={selectedPec}
            style={{
                color: 'rgb(9, 109, 217)',
                background: 'rgb(230, 247, 255)',
                borderColor: 'rgb(145, 213, 255)',
                padding: '20px'
            }}
            onSelectPec={selectHandler}
            />

    return (
        <>
        {component}
        </>
    )
}

export default DataValidation