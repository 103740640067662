import React from 'react'
import get from 'lodash/get'
import set from 'lodash/set';

import {useNavigate} from "react-router";
//import {NetworkError} from "rest-hooks"
import {PaperLayout, VerticalLayout} from "@nic/ui-comps";
import {StoreContext} from "../context/FormContext";
import Form, {
    FormError,
    LayoutKind,
    Schema,
    SchemaType,
    UIEffect,
    UILayout,
    UIOperation
} from "../modules/form/container/Form";
import IPACheck from "./IPACheck";
import SelectUO from "./SelectUO";
import RestWrapper from "../components/RestWrapper";
import {notification} from "antd";
import {AMM_CODE, SELECTED_DATA, SELECTED_PEC, VALIDATED_PATH} from "../configuration/index";
import GovSummary from "../components/GovSummary";
import DataValidation from "./DataValidation";
import ViewAmministrazioneData from "../components/ViewAmministrazioneData";
import {authFetch, headerFromToken} from "../utils/AuthUtils";
import {useKeycloak} from "@react-keycloak/web";
import Utils from "../api/Utils";
import {OP_COMPLETA} from "../configuration";
import UOCheckbox from "../components/UOCheckbox";
import {emptyData} from "../utils/FieldUtils";
import BoxedCheckbox from "../components/BoxedCheckbox";


interface MainWizardI {
    loading: boolean
}


const UOERRORS:  Record<string,string> = {
    '400': "Nessuna PEC individuata per l’amministrazione selezionata"
}

const MainSchema: Schema = {
    name: 'ipa',
    type: SchemaType.OBJECT,
    properties: {
        ipaCode: {
            required: true,
            type: SchemaType.STRING,
        },
        checkUO: {
            required: false,
            type: SchemaType.BOOLEAN,
        },
        amministrazioneData: {
            required: false,
            type: SchemaType.STRING,
        },
        unitaOperativa: {
            required: false,
            type: SchemaType.STRING,
        },
        confirmData: {
            required: false,
            type: SchemaType.BOOLEAN
        },
        confirmPersonalData: {
            required: false,
            type: SchemaType.BOOLEAN
        }

    }
};

const MainUISchema: UILayout = {
    kind: LayoutKind.GROUP,
    path: 'ipa',
    options: {stepped: true},
    elements: [
// Steo 1
        {
            title: 'Seleziona Ente',
            description: 'Inserire il codice IPA della Pubblica Ammininistrazione che deve essere validata ai fini della registrazione dei domini gov.it. Verrà effettuato un controllo per verificare se l\'amministrazione selezionata è abilitata a registrare i domini gov.it.',
            kind: LayoutKind.EMBEDDED,
            path: 'ipa.ipaCode',
            label: 'Codice IPA',
            error: 'Campo Obbligario',

            validator: (data: any) => {
                // console.log('Validator')
                let errors = new Array<FormError>();

                const ipaCode = get(data, 'ipa.ipaCode')
                const amministrazione = get(data, 'ipa.amministrazione')

                if (ipaCode === undefined) {
                    errors.push({path: 'ipa.ipaCode', type: 'Deve essere validato il codice IPA prima di poter continuare'})
                    notification.error({
                        message: 'Amministrazione',
                        description: "Il Codice IPA dell'amministrazione è in campo obbligatorio"
                    })
                } else {

                    if (amministrazione === undefined) {
                     notification.error({
                         message: 'Amministrazione',
                         description: "Il Codice IPA dell'amministrazione deve essere verificato che esista e che l'amministrazione sia abilitata a registrare domini gov.it"

                     })
                        errors.push({
                            path: 'ipa.ipaCode',
                            type: 'Amministrazione deve essere controllata che esista e sia abilitata'
                        })

                    } else {

                        if (!amministrazione.enabled) {
                            notification.error({
                                message: 'Amministrazione',
                                description: "Il codice IPA deve essere relativo ad Amministrazione Pubblica abilitata a registrare domini gov.it"
                            })
                            errors.push({
                                path: 'ipa.ipaCode',
                                type: 'Amministrazione non abilitata a registrare domini gov.it'
                            })
                        }
                    }
                }
                return errors
            },
            embeddedComponent:

                <VerticalLayout style={{alignItems: 'flex-start', textAlign: 'left'}}>
                    <IPACheck path={'ipa.ipaCode'} ammPath={'ipa.amministrazione'}/>
                </VerticalLayout>
            ,
        },
// Steo 2

        {
            title: 'Seleziona Unità Organizzativa',
            description: "Selezionare, se necessario, un’Unità Organizzativa. Se non verrà selezionata alcuna Unità Organizzativa, la procedura effettuerà la validazione dell’amministrazione indicata al punto precedente e i domini gov.it saranno assegnati a quest’ultima.",
            kind: LayoutKind.VERTICAL,
            validate: true,
            validator: (data: any): Array<FormError> => {
                let errors = [] as Array<FormError>;
                const _checkUO = get(data, 'ipa.checkUO')
                const _selectedUO = get(data, OP_COMPLETA);
                if (_checkUO && _selectedUO === undefined) {
                    errors.push({
                        path: OP_COMPLETA,
                        type: 'Bisogna selezionare una Unità Organizzativa'
                    })
                    notification.error({
                        message: 'Seleziona Unità Organizzativa',
                        description: "Bisogna selezionare una Unità Organizzativa"
                    })
                }


                return errors;
            },
            elements: [

                {
                    kind: LayoutKind.EMBEDDED,
                    path: 'ipa.amministrazioneData',
                    label: 'Info Amministrazione',
                    validate: false,
                    embeddedComponent:

                            <ViewAmministrazioneData
                                ammPath={AMM_CODE}
                            />

                },
                {
                    kind: LayoutKind.EMBEDDED,
                    path: 'ipa.checkUO',
                    label: 'Seleziona Unità Organizzativa',
                    validate: false,
                    embeddedComponent: <UOCheckbox/>,
                },

                {
                    kind: LayoutKind.EMBEDDED,
                    path: 'ipa.unitaOperativa',
                    label: 'Seleziona Unità Organizzativa',
                    validate: false,
                    rule: {
                        effect: UIEffect.SHOW,
                        condition: {operation: UIOperation.EQ, path: 'ipa.checkUO', value: true}

                    },

                    embeddedComponent:
                        <RestWrapper>
                            <SelectUO
                                amministrazionePath={'ipa.amministrazione'}
                                uoPath={'ipa.uo'}
                            />
                        </RestWrapper>

                },
            ]
        },

// Steo 3
        {
            kind: LayoutKind.VERTICAL,
            title: 'Verifica dei Dati',
            description: "Verifica dei dati e selezione dell'indirizzo PEC",
            validate: true,
            validator: (data: any): Array<FormError> => {
                // console.log('Chiamo validator 3 passo')
                let errors = [] as Array<FormError>;


                const ipaCheck: boolean = get(data, 'ipa.checkUO')

                const selectedDATA = get(data, SELECTED_DATA);

                const selectedPEC = get(data, SELECTED_PEC);

                if (emptyData(selectedDATA)) {
                    errors.push({
                        path: 'ipa.checkUO',
                        type: 'Amministrazione non abilitata a registrare domini gov.it'
                    })
                    notification.error({
                        message: 'Verifica Dati',
                        description: "Errore nella verifica dei dati"
                    })
                }


                if (ipaCheck && !emptyData(selectedDATA) && selectedDATA.codiceUO === undefined) {
                    errors.push({
                        path: 'ipa.checkUO',
                        type: 'Amministrazione non abilitata a registrare domini gov.it'
                    })
                    notification.error({
                        message: 'Unità Organizzativa',
                        description: "Bisogna selezionare una Unità Organizzativa tra quelle disponibili"
                    })
                }

                if (!emptyData(selectedDATA) && selectedDATA.pecs.length > 1 && selectedPEC === undefined) {
                    errors.push({
                        path: 'ipa.selectedPec',
                        type: 'Bissogna selezionare una pec tra quelle disponibili'
                    })
                    notification.error({
                        message: 'PEC',
                        description: "Bisogna selezionare una pec tra quelle disponibili"
                    })
                }

                return errors;
            },

            elements: [
                {
                    kind: LayoutKind.EMBEDDED,
                    validate: false,
                    embeddedComponent:
                        <RestWrapper errorsOverride={UOERRORS}>
                            <DataValidation
                                ammPath={'ipa.amministrazione'} uoPath={'ipa.unitaOrganizzativa'}
                            />
                        </RestWrapper>
                },

            ]
        },


// Step Finale

        {
            kind: LayoutKind.VERTICAL,
            title: 'Conferma',
            description: 'Conferma delle informazioni selezionate.',
            validate: true,
            validator: (data: any): Array<FormError> => {
                // console.log('Chiamo validator 3 passo')
                let errors = [] as Array<FormError>

                const _consentData = get(data, 'ipa.confirmData')
                const _consentPersonalData = get(data, 'ipa.confirmPersonalData')
                if (!_consentData) {
                    errors.push({
                        path: 'ipa.confirmData',
                        type: 'Bisogna dichiarare di essere stato delegato dall’amministrazione e che i dati visualizzati sono corretti'
                    })
                    /*
                    notification.error({
                        message: 'Dichiarazione',
                        description: "Bisogna dichiarare di essere delegato e che i dati visualizzati siano corretti"
                    })
                    */
                }

                if (!_consentPersonalData) {
                    errors.push({
                        path: 'ipa.confirmPersonalData',
                        type: 'Bisogna accettare che i dati personali possano essere utilizzati per le finalità indicate'
                    })
                    /*
                    notification.error({
                        message: 'Consenso',
                        description: "Bisogna accettare che i dati personali possano essere utilizzati per le finalità indicate"
                    })
                    */
                }

                return errors
            },

            elements: [

                {
                    kind: LayoutKind.EMBEDDED,
                    title: 'Conferma',
                    description: 'Conferma delle informazioni selezionate.',
                    validate: false,
                    embeddedComponent: <GovSummary/>
                },
                {
                    kind: LayoutKind.EMBEDDED,
                    path: 'ipa.confirmData',
                    label: 'Conferma i dati selezionati',
                    error: 'Valore obbligatorio',
                    validate: false,
                    embeddedComponent: <BoxedCheckbox
                        label={"Dichiaro di essere stato delegato dall’amministrazione selezionata ad effettuare la validazione dei dati dell’amminitrazione stessa e a richiedere il codice di validazione per la registrazione dei domini gov.it. Dichiaro, inoltre, che i dati sopra mostrari sono corretti."}
                        path={"ipa.confirmData"}
                        className={'info-box'}
                    />
                },
                {
                    kind: LayoutKind.EMBEDDED,
                    path: 'ipa.confirmPersonalData',
                    label: 'Conferma i dati selezionati',
                    error: 'Valore obbligatorio',
                    validate: false,
                    embeddedComponent: <BoxedCheckbox
                        label={"Accetto che i miei dati personali (nome, cognome, e-mail, codice fiscale) siano trasmessi all’indirizzo PEC dell’amministrazione selezionata"}
                        path={"ipa.confirmPersonalData"}
                        className={'info-box'}
                    />

                }
            ]
        }



    ]

}


const Translation: Record<string, string> = {
    'Prossimo': 'Prossimo',
    'Fatto': 'Conferma',
    'Precedente': "Precedente",
    "ipa.confirmData.error": 'Valore Obbligatorio',
}

const t = (label: string) => {
    return (Translation[label] ?? label)
}






const sendValidationData = async (kc: Keycloak.KeycloakInstance, data: any) => {
    const fetchHeader = (kc.authenticated ? {headers: headerFromToken(kc?.token)} : {});

    const  {url, body} = data

    //console.log('POST: ', body, JSON.stringify(body))


    const options = {
        headers: {...fetchHeader.headers},
        method: 'POST',
        body: JSON.stringify(body)
    };
    const res = await fetch(url, options as any);
    return res;

};


const MainWizard: React.FC<MainWizardI> = (props) => {
    const {keycloak} = useKeycloak()
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [errors, setErrors] = React.useState([]as Array<FormError>);
    const store = React.useContext(StoreContext);
//    const { fetch, getError, getResponse } = useController();
    const navigate = useNavigate();


    const loadConfiguration = async (kc: Keycloak.KeycloakInstance) => {
        const fetchHeader = (keycloak.authenticated ? {headers: headerFromToken(keycloak?.token)} : {});
        const path = `${Utils.govWeb()['urlRoot']}/${Utils.govWeb()['configuration']}`;
        const res = await fetch(path, fetchHeader as any);
        return res;
    };


    React.useEffect(() => {
        authFetch(keycloak, loadConfiguration).then(res => {

            if (!res.ok) {
                console.log('Errore: ');
            }
            return res.json();
        }).then(json => {

            set(store.getData(), 'configuration', json);
        }).catch( e => {
            console.error('Errore ', e);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <PaperLayout asCard={true}>
            <Form
                className={'gov-buttons'}
                loading={isLoading}
                errors={errors}
                context={StoreContext}
                store={store}
                schema={MainSchema}
                uischema={MainUISchema}
                showButtons={false}
                useTranslation={true}
                t={t}
                onReset={() => {
                    console.log('RESET: ', store.getData());
                    setErrors([]);
                    store.handleUpdate('ipa', {});

                }}
                handleAction={(data: any) => {
                   // console.log('DATA: ', data)

                    const ammValidateData: Record<string, string> = {}

                    /*
                        "codiceIpa": "m_bac",
                        "codiceUO": "Z7CU6N",
                        "pec": "abc@pec.it"
                     */
                    const selectedDATA = get(data, "ipa.selectedData")
                    const selectedPec = get(data, "ipa.selectedPec") ?? selectedDATA.pecs[0];
                    ammValidateData['codiceIpa'] = selectedDATA.codiceIpa;
                    if (selectedDATA.codiceUO !== undefined && selectedDATA.codiceUO.length > 0) {
                        ammValidateData['codiceUO'] = selectedDATA.codiceUO;
                    }
                    ammValidateData['pec'] = selectedPec;



                    const path = `${Utils.govWeb()['urlRoot']}/${Utils.govWeb()['amministrazioniValidate']}`

                    let error = false;
                   // let errorCode = 200;
                    setIsLoading(true)
                    authFetch(keycloak, sendValidationData, {url: path, body: {...ammValidateData}}).then(res => {
                        setIsLoading(false)
                        if (!res.ok) {
                            error = true;
                            //console.log('error: ', res)
                            notification.error({
                                message: 'Validazione Dati',
                                description: 'Si è verificato errore'
                            })
                        }
                        return res.json()
                    }).then(json => {

                        if (!error) {
                            notification.success({
                                message: 'Validazione Dati',
                                description: `I dati selezionati sono corretti e sono stati inviati.`
                            })


                            console.log('Store finale> ', store.getData());

                            const jsonFinale = {data: json, configuration: get(store.getData(), 'configuration')};


                            //const jsonString = typeof json === 'string' ? json : JSON.stringify(json);
                            const jsonString = JSON.stringify(jsonFinale);


                            const uri = encodeURI(jsonString);
                            const encoded = btoa(uri)
                            const params = new URLSearchParams()
                            params.set('res', encoded)
                            navigate(VALIDATED_PATH+`?${params}`)

                        } else {
                            //console.log('Prendi i dati da errore ', json)
                            //console.log('Prendi i dati da errore ', json)
                            if (json.errors !== undefined) {
                                json.errors.forEach((e: any) => {
                                    notification.error({
                                        message: 'Validazione Dati',
                                        description: e.message
                                    })
                                })
                            } else {
                                notification.error({
                                    message: 'Validazione Dati',
                                    description: json.message
                                })
                            }
                        }
                    })
                }}
            />
        </PaperLayout>
    )
}

export default MainWizard
