/**
 * Path per indirizzare context store della form
 * Contiene intera struttura dati di verifica dell'amminiistrazione
 * (presente)
 * @type {string}
 */
export const AMM_COMPLETA = "ipa.amministrazioneFull"

/**
 * Path per indirizzare context store della form
 * Contiene intera struttura dati di verifica dell'unirà operativa selezionata
 * (se presente)
 * @type {string}
 */
export const OP_COMPLETA = "ipa.unitaOrganizzativa"

/**
 *
 */
export const CHECK_UO = "ipa.checkUO"

/**
 * Path per indirizzare context store della form
 * contiene codice della AMMINSTRAZIONE SELEZIONATA
 * @type {string}
 */

export const AMM_CODE = "ipa.amministrazione"

/**
 * Path per indirizzare context store della form
 * contiene codice della UNITA ORGANIZZAZIONE selezionata
 * @type {string}
 */
export const UO_CODE = "ipa.uo"

export const IPA_CODE = "ipa.ipaCode"

export const SELECTED_PEC = 'ipa.selectedPec'

export const SELECTED_UO = 'ipa.selectedUO'


export const SELECTED_DATA = 'ipa.selectedData'
export const VALIDATED_DATA = 'ipa.validatedData'
export const CONFIRM_DATA = "ipa.confirmData"

export const VALIDATED_PATH = '/riepilogo'
export const VERIFY_PATH = '/verifica'

export const RED = '#e70000'

export const EXISTING_VALIDATION = "L'amministrazione selezionata è stata già validata di recente. Se procederai con la conferma verrà emesso un nuovo 'codice di validazione' e quello precedentemente generato non sarà più utilizzabile per la registrazione dei domini gov.it.";
export const PUBTEST_WARNING = "Il sistema di test invia il messaggio all’indirizzo email specificato dall’utente in fase di registrazione o associato al suo account SPID";