import * as React from 'react';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import {useEffect} from "react";

export interface IStoreContext {
    handleUpdate: (path: string, data: any) => void;
    getData: () => any;
    handleValidate: (path: string, value: any, handler: (value: any) => boolean) => void;
    hasError: () => any;
    debug?: boolean;
}

type IStoreState = any;

export const StoreContext = React.createContext<IStoreContext>({
    handleUpdate: (path: string, data: any)=>{},
    getData: ()=>{},
    handleValidate: (path: string, value: any, handler: (value: any) => boolean) => {},
    hasError: () => {},
    debug: false
});

interface IStore {data?: any, debug?: boolean, updateHandler?: (data: any)=> void}

export const Store: React.FC<IStore> = (props) => {
    const [stored, setStored] = React.useState<IStoreState>({});
    const [error, setError] = React.useState<IStoreState>({});
    const [debug, setDebug] = React.useState(false);

    useEffect(()=> {
        if (props.data !== undefined) {
           // console.log('setstored:: ', props.data);
            setStored(cloneDeep(props.data));
        }
    }, [props.data]);

    useEffect(()=> {
        if (props.debug !== undefined) {
            setDebug(props.debug);
        }
    }, [props.debug]);


    const getData = () => {
        return stored;
    };

    const hasError = () => {
        return error;
    };

    const handleUpdate = (path: string, data: any): void => {
        if (debug) {
            console.log('Store: handleUpdate path: ', path, ' data: ', data, ' prevStore: ', stored);
        }
        setStored( (store: any) => set(cloneDeep(store), path, data));

        if (props.updateHandler !== undefined) {

            props.updateHandler({data: data, path: path, prev: stored});
        }
    };

    const handleValidate = (path: string, value: any, handler: (value: any) => boolean): void => {

        const errorValue = handler(value);
        setError((error: any) => set(cloneDeep(error), path, errorValue));
    };

    return (
        <StoreContext.Provider
            value={{handleUpdate: handleUpdate.bind(stored), getData: getData, handleValidate: handleValidate, hasError: hasError}}
        >
            {props.children}
        </StoreContext.Provider>
    )
};