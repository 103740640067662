import {Map} from '../types/Map';
import  moment, {Moment} from 'moment';

export const holidaysDates: Map<string> = {
// Rappresentazione MM-DD
    "01-01" : "Capodanno",
    "01-06" : "Befana" ,
    "04-25" : "Liberazione" ,
    "05-01" : "Festa Lavoro" ,
    "06-02" : "Festa Repubblica",
    "08-15" : "Festa Assunzione",
    "11-01" : "Ognissanti",
    "12-08" : "Immacolata Concezione",
    "12-25" : "Natale",
    "12-26" : "Santo Stefano",
    "06-17" : "Patrono Pisa"
};

export const getEaster = (year: number) => {

    const a = (year / 100 | 0) * 1483 - (year / 400 | 0) * 2225 + 2613;
    const b = ((year % 19 * 3510 + (a / 25 | 0) * 319) / 330 | 0) % 29;
    const c = 148 - b - ((year * 5 / 4 | 0) + a - b) % 7;

    return moment({year: year, month: (c / 31 | 0) - 1, day: c % 31 + 1});
};
// Da Lunedì a Venerdì è giorno lavorativo (se non è festività)
export const isInBusinessDayRange = (date: Moment): boolean => {
    const giorno = date.day();
    return (giorno >= 1 && giorno <= 5);
};

/*
const isEasterHoliday = (date: Moment): boolean => {
    const year = date.year();
    const easter = getEaster(year);

    const lunediAngelo = moment(easter).add(1, "days");

    const isEaster = moment(easter.format('YYYY-MM-DD')).isSame(moment(date.format('YYYY-MM-DD')));
    const isLunediAngelo = moment(lunediAngelo.format('YYYY-MM-DD')).isSame(moment(date.format('YYYY-MM-DD')));

    return  isEaster || isLunediAngelo;
};
*/

export const isHoliday = (date: Moment): boolean => {

    let res: boolean = false;
    const dateS:string = date.format('MM-DD');
    const isHoliday = holidaysDates[dateS];
    if (isHoliday !== undefined) {
        res = isInBusinessDayRange(date);
        //res = true;
    } else {
        // Controllo se è Pasqua e/o giorno angelo
        const year = date.year();
        const easter = getEaster(year);

        const lunediAngelo = moment(easter).add(1, "days");

        const isEaster = moment(easter.format('YYYY-MM-DD')).isSame(moment(date.format('YYYY-MM-DD')));
        const isLunediAngelo = moment(lunediAngelo.format('YYYY-MM-DD')).isSame(moment(date.format('YYYY-MM-DD')));

        res = isEaster || isLunediAngelo;
    }

    return res;
};


export const minusBusinessDays = (startDate: Moment, days: number): Moment => {

    let date = moment(startDate);

    let remaining = days;

    do  {

        if (isHoliday(date)) {
            date = date.subtract(1, 'd');
        } else {
            if (!isInBusinessDayRange(date)) {
                date = date.subtract(1, 'd');
            } else {
                date = date.subtract(1, 'd');
                remaining--;
            }
        }

    } while (remaining  > 0);

    return date;
};



export const isInDataRange = (startDate1: Moment, endDate1: Moment, startDate2: Moment, endDate2: Moment): boolean => {
  return (startDate1.isSameOrBefore(endDate2) && endDate1.isSameOrAfter(startDate2));
};


export const isDateInRange = (date: Moment, start: Moment, end: Moment): boolean => {
    return isInDataRange(date, date, start, end);
};

// Numero di vacanze presenti in intervallo do tempo
export const numHolidays = (start: Moment, end: Moment): number => {
    let res = 0;
    for (let key in holidaysDates) {
        let endHolidayString = end.year() + '-' + key;
        let momentDate = moment(endHolidayString);
        res += (isDateInRange(momentDate, start, end) && isInBusinessDayRange(momentDate) ? 1 : 0);
        //res += (isDateInRange(momentDate, start, end) ? 1 : 0);
        if (start.year() !== end.year()) {
            let startHolidayString = start.year() + '-' + key;
            momentDate = moment(startHolidayString);
            res += (isDateInRange(momentDate, start, end) && isInBusinessDayRange(momentDate)? 1 : 0);
            //res += (isDateInRange(momentDate, start, end) ? 1 : 0);
        }

    }
    return res;
};