import React from 'react'
import {useResource} from 'rest-hooks';
import EsistenzaAmministrazione from "../api/EsistenzaAmministrazione";
import {VerticalLayout} from "@nic/ui-comps";
import {StoreContext} from "../context/FormContext";
import AmministrazioneViewer from "../components/AmministrazioneViewer";
import {AMM_CODE, IPA_CODE} from "../configuration/index";

interface  IControllaAmministrazione {
    ipaCode: string
    path: string
}


const ControllaAmministrazione: React.FC<IControllaAmministrazione> = (props) => {

   const {ipaCode} = props;
   // const [selectUO, setSelectUO] = React.useState<boolean>(false);
    const  store = React.useContext(StoreContext);
    const amministrazione:EsistenzaAmministrazione = useResource(EsistenzaAmministrazione.detail(), {codiceIpa: ipaCode})

    //const check:AmministrazioniValidate = useResource(AmministrazioniValidate.detail(), {codiceIpa: ipaCode})


    React.useEffect(() => {
      //  console.log('Rifaccio useEffect; ', amministrazione)
        if (amministrazione) {
            store.handleUpdate(IPA_CODE, amministrazione.codiceIpa)
            store.handleUpdate(AMM_CODE,  amministrazione)

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amministrazione])



    let component = <></>

    if (amministrazione !== undefined) {

        component = <AmministrazioneViewer amministrazione={amministrazione} view={'full'} direction={'row'}/>
    } else {

        component = <span>Missing</span>
    }

    return (
        <VerticalLayout style={{gap:20}}>
            {component}
        </VerticalLayout>
    )
}

export default ControllaAmministrazione