import React from 'react'
import {useKeycloak} from "@react-keycloak/web";
import {Div, HorizontalLayout} from "@nic/ui-comps";
import {KeycloakInstance} from "keycloak-js";
import {Value} from "../utils";
import {Divider, Space} from "antd";


interface IUserInfo {
    givenName: string
    familyName: string
    companyName?: string
    email: string
    fiscalCode: string
}





function getUserInfoFromToken(keycloak: KeycloakInstance) {
   // console.log('Instance: ', keycloak);
    const { tokenParsed } = keycloak;
    return  (
        {
            givenName: tokenParsed?.given_name,
            email: tokenParsed?.email,
            fiscalCode: tokenParsed?.ficalCode,
            familyName: tokenParsed?.family_name
        } as IUserInfo
    )
}


interface BreadcrumbI {

}

const Breadcrumb: React.FC<BreadcrumbI> = (props) => {
    const {keycloak} = useKeycloak();
    const userInfo: IUserInfo = getUserInfoFromToken(keycloak);
    return (
        <HorizontalLayout style={{justifyContent: 'flex-start', paddingLeft: '20px' }}>
            {keycloak.authenticated && <>
                <Div marginRight={'10px'}>Benvenuto</Div>
                <Space split={<Divider type="vertical" style={{backgroundColor: 'lightgray'}}/>}>
                    {
                        Value(userInfo.givenName + ' '+userInfo.familyName)
                    }

                    {
                        Value(userInfo.fiscalCode)
                    }
                    {
                        Value(userInfo.companyName)
                    }
                </Space>
            </>
            }
        </HorizontalLayout>
    )

}

export default Breadcrumb