import React from 'react';
import {PaperLayout} from "@nic/ui-comps";
import {Divider} from "antd";

interface GroupLayoutProps {
    title?: string;
    titleStyle?: React.CSSProperties;
    className?: string;
    children: React.ReactNode | React.ReactNode[];
    asCard?: boolean;
    simple?: boolean;
}
export const GroupLayout: React.FunctionComponent<GroupLayoutProps> = (props) => {
    const component = (
        props.title !== undefined ?
            ( <><Divider orientation='left' className={props.className}><span style={props.titleStyle}>{props.title}
            </span></Divider> {props.children}</>) :
            ( <PaperLayout simple={props.simple} className={props.className}>{props.children}</PaperLayout> )
    );
    return (
        <>{component}</>
    )
};