import React from 'react';
import {Button} from "antd";
import remove from 'lodash/remove';
import set from 'lodash/set';
import get from 'lodash/get';
import {CloseCircleTwoTone} from '@ant-design/icons';

import {buildField, buildLayout, FormInterface, Layout, Schema} from "./Form";

import {VerticalLayout, PaperLayout, HorizontalLayout, Div} from "@nic/ui-comps";

import {IStoreContext} from "../../../context/FormContext";
import Title from "../components/layout/Title";


interface ArrayContainerInterface {
    //arrayPath: string;
    //elementsPath?: string;
    //element: React.ReactElement<any>;
    //index: number;
    schema: Schema;
    path: string;
    layout: FormInterface;
    uiLayout: Layout;
    context: React.Context<IStoreContext>;
    id: string | undefined;
}
/*
interface StateInterface {
    id: number;
}
*/
const ArrayContainer: React.FC<ArrayContainerInterface> = (props) => {
    const schema = props.schema !== undefined ? props.schema : {} as Schema;
    const store = React.useContext(props.context);
    const [element, setElement] = React.useState([{id: 0}]);
    const addElement = () => {
        // Aggiungere un elemento all'array
        setElement([...element, {id: element.length}]);
    };

    const delElement = (index: number) => {
        // Cancellare eelemento dall'array

        // Controllare numero di items.

        setElement(remove(element, (a: any) => {
            return a.id !== index;
        }));

        let arr = get(store.getData(), props.path);
        let idx = 0;
        let newArr = [];
        while (idx < arr.length) {
            if (idx !== index) {
                newArr.push(arr[idx]);
            }
            idx++;
        }
        // Clean dell'array dello stato
        set(store.getData(), props.path, newArr);
    };

    let p: Array<React.ReactElement<any>> = [];

    element.forEach((e, idx) => {
        //console.log('arraycycle: ',     schema,  ' path ', props.path + `[${idx}]`, ' uiPath ', props.path, props.layout, props.uiLayout);
        //const thePath = (schema.name !== undefined ? props.path+'.'+schema.name : props.path);
        p.push(<PaperLayout key={props.path + `[${idx}]`} style={{width: '100%'}}>
            <Button style={{top: '-1.91rem', right: '-98.1%', border: 'none', fontSize: '1.1rem', background: 'none'}} onClick={()=> {delElement((idx))}}>
                <CloseCircleTwoTone/>
            </Button>
            <HorizontalLayout>

                {buildLayout(props.uiLayout, buildField(schema,  props.path + `[${idx}]`, props.path, props.layout, props.context))}
            </HorizontalLayout>
        </PaperLayout>)
    });

    return (
        <PaperLayout  key={props.id} style={{width: '100%'}}>
            <VerticalLayout>
            <Title title={props.uiLayout.title !== undefined ? props.uiLayout.title : props.path}/>
            {p}
            <Div>
                <Button onClick={()=> {addElement()}} type={'dashed'} style={{width: '60%'}}>Aggiungi Elemento</Button>
            </Div>
            </VerticalLayout>
        </PaperLayout>

    )
};

export default ArrayContainer;