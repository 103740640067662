import {Map} from '../types/Map';

export interface IResponse extends Error {
    type: string;
    redirected: boolean;
    status: number;
    ok: boolean;
    statusText: string;
    url: string;
}
export const ROOT_API = '/api';
export const APPLICANTS_API = 'domains-verify/applicants';
export const REQUESTS_API = 'domains-verify/requests';
export const DOCUMENTS_API = 'domains-verify/documents';
export const TEMPLATE_API = 'message-template';
export const REQUESTS_STATS = '/domains-verify/stats/requests/averageTime';
export const EVENTS_STATS = '/domains-verify/stats/events/';
export const CUSTOMERS_SERVICE = 'customers-service/v2';
export const HEADER_COLOR = '#0c60aa';

export const BUSINESS_DAYS = 10;


//export const MAX_TEXTAREA_ELEMENTS = 512;


/*
export enum VERIFY_STATUS {
    SUBMITTED = "SUBMITTED",
    ACCEPTED = "ACCEPTED",
    REJECTED = "REJECTED",
    DOC_WAITING = "DOC_WAITING",
    DOC_RECEIVED = "DOC_RECEIVED",
    COMPLETED_VALID = "COMPLETED_VALID",
    COMPLETED_INVALID = "COMPLETED_INVALID"
}
*/

export enum VERIFY_STATUS {
    SUBMITTED ,
    ACCEPTED ,
    REJECTED ,
    DOC_WAITING ,
    DOC_RECEIVED ,
    RCPT_WAITING,
    FAILED_DELIVERY,
    COMPLETED_VALID ,
    COMPLETED_INVALID
}

export const isReceipt = (type: string): boolean => {
    return (type === 'RCPT_OK' || type === 'RCPT_FAILED');
};


export const convertBoolean: Map<boolean> = {
    Vero: true,
    true: true,
    Falso: false,
    false: false,
    Si: true,
    No: false
};