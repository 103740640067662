import React from 'react';


export interface IValidateContext {
    handleValidate: () => any;
    resetValidation: () => void;
    forceErrors: (errors: Record<string, string>) => any;
    errors: Record<string, string>;
}
export const ValidateContext =
    React.createContext<IValidateContext>({
            handleValidate: ()=>{},
            errors: {},
            forceErrors: ()=>{},
            resetValidation: ()=> {}
        });
