import React from 'react'
import get from 'lodash/get'
import {Input, Button} from 'antd'
import {IStoreContext, StoreContext} from "../context/FormContext";
import {Div, HorizontalLayout, VerticalLayout} from "@nic/ui-comps";
import RestWrapper from "../components/RestWrapper";
import ControllaAmministrazione from "./ControllaAmministrazione";
import {AMM_CODE, CHECK_UO, CONFIRM_DATA, OP_COMPLETA, SELECTED_DATA, SELECTED_PEC} from "../configuration/index";
import {IPAERRORS} from "../configuration/Errors/index";

interface IPACheckI {
    path: string
    ammPath: string
}

/**
 *
 export const SELECTED_PEC = 'ipa.selectedPec'

 export const SELECTED_UO = 'ipa.selectedUO'


 export const SELECTED_DATA = 'ipa.selectedData'

 *
 * @param {IStoreContext} store
 */

const cleanStore = (store: IStoreContext) => {
    store.handleUpdate(SELECTED_PEC, undefined)
    store.handleUpdate(SELECTED_DATA, {})
    store.handleUpdate(CHECK_UO, false)
    //store.handleUpdate(AMM_CODE, undefined)
    store.handleUpdate(CONFIRM_DATA, false)
    store.handleUpdate(OP_COMPLETA, undefined)
}


const IPACheck: React.FC<IPACheckI> = (props) => {

    const store = React.useContext(StoreContext)
    const _data = store.getData()

    const ipaValue = get(_data, 'ipa.ipaCode')
    const [ipa, setIpa] = React.useState<string>(ipaValue ?? '')
    // console.log('checkIPA: ', ipaValue, get(data, AMM_CODE))
    const [checkIPA,setCheckIPA] = React.useState<boolean>(get(_data, AMM_CODE) !== undefined)

    return (
        <VerticalLayout style={{gap: 10, marginTop: '20px'}}>
            <Div>Codice IPA</Div>
            <HorizontalLayout style={{gap: 20}}>

                <Input
                    value={ipaValue}
                    onChange={({target}) => {
                        setCheckIPA(false);
                        setIpa(target.value);
                        store.handleUpdate(props.ammPath, undefined);
                        store.handleUpdate(props.path, target.value);
                    }}
                    onPressEnter={(e) => {
                        if (ipa.length > 0) {
                            cleanStore(store)
                            setCheckIPA(true)
                        }
                    }}
                />
                <Button className={'gov-buttons'} type={'primary'} disabled={ipa.length === 0} onClick={() => {
                    if (ipa.length > 0) {
                        cleanStore(store)
                        setCheckIPA(true)
                    }
                }}><span>Verifica</span></Button>
            </HorizontalLayout>
            {
                checkIPA && (
                    <RestWrapper errorsOverride={IPAERRORS}>
                        <ControllaAmministrazione ipaCode={ipa} path={props.path}/>
                    </RestWrapper>
                )
            }

        </VerticalLayout>


    )
}
export default IPACheck