import React from 'react';
import {NetworkErrorBoundary,  NetworkError} from "rest-hooks"

import {BrowserRouter, Route, Routes as OrigRoutes, useLocation, useParams} from "react-router-dom";

import {Location, RouteProps, RoutesProps} from 'react-router';

import {useKeycloak} from "@react-keycloak/web";
import {PaperLayout} from "@nic/ui-comps";
import {LayoutContext} from "../context/LayoutContext";



function ErrorPage({ error }: { error: NetworkError }) {
   // const location = useLocation()
    const layout = React.useContext(LayoutContext)
    // console.log('Location: ', location)

    React.useEffect(() => {
        const  _error = (
            <PaperLayout asCard={true}>
                {error.status} {error.response && error.response.statusText}
            </PaperLayout>
        )
        layout.setLayoutElement('BODY', _error);
    })
    return (<></>)
}



export interface PrivateRouteI  extends RouteProps {
    fallback: React.ReactNode;
    checkParams?: (params: any) => boolean;
    // action: ( location: Location  ) => void;
    onAuthenticated?: (token: Keycloak.KeycloakInstance, location: Location) => void;
    onError?: (error: any, location: Location) => void;
    onUpdated?: (token: Keycloak.KeycloakInstance, location: Location) => void;
    noMatch?: React.ReactNode;
    error: React.ReactElement<any>;
}

const mainURL = window.location.origin;


export const PrivateRoute: React.FC<PrivateRouteI> = (props) => {
    const {path, element, ...rest} = props;
    return (
        <Route
            path={path}
            element={
                <ProtectedResource
                    {...rest}
                >
                    {element}
                </ProtectedResource>
            }
        />
    )
};


export interface ProtectedRouteI {
    error: React.ReactNode;
    checkParams?: (params: any) => boolean;
    fallback: React.ReactNode;
    onAuthenticated?: (token: Keycloak.KeycloakInstance, location: Location) => void;
}

interface ProtectedResourceI {
    fallback: React.ReactNode;
}

export const ProtectedResource:React.FC<ProtectedResourceI> = (props) => {
    const {keycloak, initialized} = useKeycloak();

    const component = initialized && keycloak.authenticated ? props.children : props.fallback;

    return (
        <>{component}</>
    )
};

export const ProtectedRoute: React.FC<ProtectedRouteI> = (props) => {
    const {onAuthenticated, checkParams} = props;
    const location = useLocation();
    const params = useParams();
    //const match = useMatch(props.path)
    const {keycloak, initialized} = useKeycloak();
    //const tokenReady = initialized && keycloak !== undefined ;

    const {token} = keycloak;


    const tokenReady = initialized && keycloak !== undefined && token !== undefined;

    const checked = checkParams !== undefined ? checkParams(params) : true;

   // const renderable = (initialized && tokenReady && (keycloak?.authenticated ?? false));

    const initToken = (token: Keycloak.KeycloakInstance,location: Location): void => {

        if (token !== undefined && initialized) {

            if (!token.authenticated) {
                token.login({redirectUri: mainURL + location.pathname + location.search});
            }
        }

        if (token.authenticated && onAuthenticated !== undefined) {
            onAuthenticated(token, location);
        }

    };

    React.useEffect(()=> {
        if (keycloak !== undefined) {
            initToken(keycloak, location);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keycloak, initToken]);

    let component = <>{props.fallback}</>;

    if (tokenReady && keycloak.authenticated && checked) {
        component = <React.Suspense fallback={<>{props.fallback}</>}>{props.children}</React.Suspense>;
    } else {
        if ((!keycloak.authenticated && token !== undefined) || !checked) {
            component = <>{props.error}</>;
        }
    }

    return (
        <>{component}</>
    )
};


export const Switch: React.FC<RoutesProps> = (props) => {
    return (
        <BrowserRouter>
            <NetworkErrorBoundary fallbackComponent={ErrorPage}>
            <OrigRoutes>
                {props.children}
            </OrigRoutes>
            </NetworkErrorBoundary>
        </BrowserRouter>
    )
};