import {Resource} from '@rest-hooks/rest';
import AuthoredResource from "./AuthoredResource";
import Utils from "./Utils";
import Address from "./Address";

export default class UnitaOperative extends AuthoredResource {
    readonly address: Address = new Address()
    readonly codiceFiscale: string ="";
    readonly codiceIpa: string = '';
    readonly codiceUO: string = '';
    readonly denominazione: string = '';

    pk() { return this.codiceUO}

    static list<T extends typeof Resource>(this: T) {
        return super.list().extend({
            schema: { elements: [this], page: {} },
        });
    }


    static urlRoot = `${Utils.govWeb()['urlRoot']}/${Utils.govWeb()['unitaOrganizzative']}`;

}