import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import itIT from 'antd/lib/locale/it_IT';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ReactKeycloakProvider} from "@react-keycloak/web";
import {AuthClientError, AuthClientEvent} from '@react-keycloak/core';
import keycloak from './keycloak';
import {CacheProvider} from "rest-hooks";
import 'antd/dist/antd.min.css'
import AuthoredResource from "./api/AuthoredResource";
import "@fontsource/titillium-web";

const keycloakProviderInitConfig = {
    onLoad: "check-sso",
    checkLoginIframe: true
};



ReactDOM.render(
  <React.StrictMode>
      <ReactKeycloakProvider
          authClient={keycloak}
          initOptions={keycloakProviderInitConfig}
          onEvent={(event: AuthClientEvent, error?: AuthClientError) => {

              switch (event) {
                  case 'onAuthSuccess':
                      AuthoredResource.setToken(keycloak)
                      break;
                  case 'onAuthRefreshSuccess':
                //      console.log('keycloak token refreshed', keycloak.token);
                      AuthoredResource.setToken(keycloak)
                      break;
                  case 'onAuthRefreshError':
                  case 'onAuthLogout':
                  case 'onAuthError':
                      console.error('ERROR:::> ', event)
                      keycloak.logout();
                      break;
                  default:
                    //  console.log('Default Event: ', event)
                      break;
              }
              if (error !== undefined) {
                  console.error('ERROR:::> ', error);
                  keycloak.logout();
              }

          }}

      >
          <CacheProvider>
              <ConfigProvider locale={itIT}>
                <App />
              </ConfigProvider>
          </CacheProvider>
      </ReactKeycloakProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
