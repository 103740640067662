import React from 'react'
import {CloseCircleOutlined} from '@ant-design/icons'
import {Div, Flex, VerticalLayout} from "@nic/ui-comps";

interface TitleCloseableI {
    label: string
    action: () => void
}


const TitleCloseable: React.FC<TitleCloseableI> = (props) => {
    return (
        <VerticalLayout
            style={{
                border: '1px solid lightgray',
                padding: '21px',
                borderRadius: '10px'
            }}
        >
            <Flex direction={'row'} justify={'space-between'} style={{marginBottom:'20px'}}>
                <Div fontWeight={'700'}>{props.label}</Div>
                <CloseCircleOutlined style={{cursor: 'pointer'}} onClick={props.action}/>
            </Flex>
            {props.children}
        </VerticalLayout>
    )
}
export default TitleCloseable