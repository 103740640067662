import React from 'react'
import {Spinner} from "./Spinner";
import {NetworkError, NetworkErrorBoundary} from "rest-hooks"
import {Div, Flex, PaperLayout} from "@nic/ui-comps";
import {faBan, faExclamationTriangle, faUserShield} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {RED} from "../configuration/index";

interface RestWrapperI {
    errorsOverride?: Record<string, string>
}

const IconMap: Record<string, React.ReactElement<any>> = {
    '400': <FontAwesomeIcon icon={faBan} color={RED}/>,
    '401': <FontAwesomeIcon icon={faUserShield} color={RED}/>,
    '404': <FontAwesomeIcon icon={faBan} color={RED}/>,
    '500': <FontAwesomeIcon icon={faExclamationTriangle} color={RED}/>
}


const MessageMap: Record<string, string> = {
    '400': 'Richiesta non Valida',
    '401': 'Utente non ha diritti per accedere ai dati',
    '404': 'Dato richiesto non esiste',
    '500': 'Errore. Riprovare più tardi',
    '1000': 'Richiesta non valida',

    '1001': 'Nessuna amministrazione trovata con il codice IPA indicato',

    '1002': "L’amministrazione selezionata non è abilitata a registrare domini gov.it",

    '1003': "Nessuna unità organizzativa trovata con il codice UO indicato",

    '1004': "Impossibile determinare il codice fiscale per l’amministrazione selezionata",

    '1005': "Nessuna PEC individuata per l’amministrazione selezionata",

    '1006': "Si è verificato un errore inatteso",

    '1007': "Nessuna amministrazione validata trovata con l’id indicato",

    '1008': "Nessuna amministrazione validata trovata o validazione scaduta",

    '1009': "Nessuna categoria trovata con il codice indicato",

    '1010': "Utente non trovato",

    '1011': "L’amministrazione risulta già abilitata",

    '1012': "L’unità organizzativa non fa parte dell’amministrazione indicata",

    '1013': "L'amministrazione selezionata non risulta più attiva",

    '1014': "L'unità organizzativa selezionata non risulta più attiva"
}


function ErrorPage({ error, override } :
                       { error: NetworkError, override?: Record<string, string>}) {
    const {status} = error;
    // console.log('Status:', status)
    const message = override !== undefined ? override[`${status}`] ?? MessageMap[`${status}`] : MessageMap[`${status}`]
    return (
        <PaperLayout asCard={false} simple={false}>
            <Flex
                direction={'row'}
                justify={'flex-start'}
                style={{gap: 20, fontSize: '1.2rem'}}
            >
                {IconMap[`${status}`] ?? <FontAwesomeIcon icon={faExclamationTriangle} color={RED}/>}
                <Div fontWeight={'700'}>
                    {message ?? 'Si è verificato un errore'}
                </Div>
            </Flex>
        </PaperLayout>
    )
}

function ErrorPageWrapper(errorOverride?: Record<string, string>) {

    if (errorOverride !== undefined) {
        return ({error}: { error: NetworkError }) => <ErrorPage override={errorOverride} error={error}/>
    } else {
        return ({error}: { error: NetworkError }) => <ErrorPage error={error}/>
    }
}


const RestWrapper: React.FC<RestWrapperI> = (props) => {
    return (
        <React.Suspense fallback={<Spinner percentage={10}/>}>
            <NetworkErrorBoundary fallbackComponent={ErrorPageWrapper(props.errorsOverride)}>
                <Flex
                    direction={'row'}
                    justify={'flex-start'}
                    width={'100%'}
                    style={{height: '100%'}}
                >
                    {props.children}
                </Flex>
            </NetworkErrorBoundary>
        </React.Suspense>
    )
}


interface SimpleRestWrapperI {
    errorComp: React.ComponentType<any>
}


export const SimpleRestWrapper: React.FC<SimpleRestWrapperI> = (props) => {
    return (
        <NetworkErrorBoundary fallbackComponent={props.errorComp}>

            {props.children}

        </NetworkErrorBoundary>
    )
}


export default RestWrapper;
