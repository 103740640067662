export default class Utils {

    static govWeb () {
        const {govWeb} = window as any;
        return govWeb;
    }

    static isPubTest(configuration: any) {
        return configuration.mailCodeDestination === 'user';
    }


}