import AuthoredResource from "./AuthoredResource";
import Utils from "./Utils";
import Address from "./Address";

export default class EsistenzaAmministrazione extends AuthoredResource {
    readonly codiceIpa: string = "";
    readonly denominazione: string = "";
    readonly codiceFiscale: string = "";
    readonly enabled: boolean = false;
    readonly address: Address = new Address()
    pk() {return this.codiceIpa}

    static urlRoot = `${Utils.govWeb()['urlRoot']}/${Utils.govWeb()['cercaAmministrazione']}`;
}