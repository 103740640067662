import React from 'react'
import { HorizontalLayout, VerticalLayout } from '@nic/ui-comps'

export interface LayoutBuilderElement {
  element?:
    | 'header'
    | 'footer'
    | 'body'
    | 'leftSide'
    | 'rightSide'
    | 'breadcrumb'
    | 'help'
    | 'navigation'
    | 'subNavigation'
    | 'horizontal'
    | 'vertical'
  options?: React.CSSProperties
  elements?: Array<LayoutBuilderElement>
}

type LayoutElement =
  | 'HEADER'
  | 'FOOTER'
  | 'LEFT-SIDE'
  | 'RIGHT-SIDE'
  | 'BODY'
  | 'BREADCRUMB'
  | 'NAVIGATION'
  | 'SUB-NAVIGATION'
  | 'HELP'

export interface LayoutContextI {
  header: React.ReactElement<any>
  breadcrumb?: React.ReactElement<any>
  body: React.ReactElement<any>
  footer: React.ReactElement<any>
  leftSide?: React.ReactElement<any>
  rightSide?: React.ReactElement<any>
  navigation?: React.ReactElement<any>
  subNavigation?: React.ReactElement<any>
  help?: React.ReactElement<any>
  resetLayouts: (elements: LayoutElement[]) => void
  setLayoutElement: (element: LayoutElement, component: React.ReactElement<any>) => void
  setLayoutElements: (settings: Record<LayoutElement, React.ReactElement<any>>) => void
}

interface LayoutManagerI {
  header: React.ReactElement<any>
  breadcrumb?: React.ReactElement<any>
  body: React.ReactElement<any>
  footer: React.ReactElement<any>
  leftSide?: React.ReactElement<any>
  rightSide?: React.ReactElement<any>
  navigation?: React.ReactElement<any>
  subNavigation?: React.ReactElement<any>
  help?: React.ReactElement<any>
  layoutConf?: LayoutBuilderElement
}

export const LayoutContext = React.createContext<LayoutContextI>({
  header: <></>,
  breadcrumb: <></>,
  footer: <></>,
  body: <></>,
  leftSide: <></>,
  rightSide: <></>,
  navigation: <></>,
  subNavigation: <></>,
  help: <></>,
  // tslint:disable-next-line
  resetLayouts: (elements: LayoutElement[]) => {},
  // tslint:disable-next-line
  setLayoutElement: (element: LayoutElement, component: React.ReactElement<any>) => {},
  // tslint:disable-next-line
  setLayoutElements: (settings: Record<LayoutElement, React.ReactElement<any>>) => {},
})

export const LayoutManager: React.FC<LayoutManagerI> = ({
  header,
  breadcrumb,
  body,
  footer,
  leftSide,
  rightSide,
  help,
  navigation,
  subNavigation,
  layoutConf,
}: React.PropsWithChildren<LayoutManagerI>) => {
  const [headerComp, setHeaderComp] = React.useState<React.ReactElement<any>>(header)
  const [breadcrumbComp, setBreadcrumbComp] = React.useState<React.ReactElement<any> | undefined>(
    breadcrumb
  )
  const [footerComp, setFooterComp] = React.useState<React.ReactElement<any>>(footer)
  const [leftSideComp, setLeftSideComp] = React.useState<React.ReactElement<any> | undefined>(
    leftSide
  )
  const [rightSideComp, setRightSideComp] = React.useState<React.ReactElement<any> | undefined>(
    rightSide
  )
  const [bodyComp, setBodyComp] = React.useState<React.ReactElement<any>>(body)
  const [helpComp, setHelpComp] = React.useState<React.ReactElement<any> | undefined>(help)
  const [navigationComp, setNavigationComp] = React.useState<React.ReactElement<any> | undefined>(
    navigation
  )
  const [subNavigationComp, setSubNavigationComp] = React.useState<
    React.ReactElement<any> | undefined
  >(subNavigation)

  const [fullLayout, setFullLayout] = React.useState<React.ReactElement<any>>(<></>)

  const layoutHandler = (layout: LayoutElement, component: React.ReactElement<any>) => {
    switch (layout) {
      case 'HEADER':
        setHeaderComp(component)
        break
      case 'FOOTER':
        setFooterComp(component)
        break
      case 'LEFT-SIDE':
        setLeftSideComp(component)
        break
      case 'RIGHT-SIDE':
        setRightSideComp(component)
        break
      case 'BODY':
        setBodyComp(component)
        break
      case 'BREADCRUMB':
        setBreadcrumbComp(component)
        break
      case 'HELP':
        setHelpComp(component)
        break
      case 'NAVIGATION':
        setNavigationComp(component)
        break
      case 'SUB-NAVIGATION':
        setSubNavigationComp(component)
        break
    }
  }

  const _layoutBuilder = React.useCallback(
    (layout: LayoutBuilderElement, key?: number) => {
      const components: React.ReactElement<any>[] = []
      switch (layout.element) {
        case 'header':
          return (
            <div key={key} style={{ ...layout.options }}>
              {headerComp}
            </div>
          )

        case 'footer':
          return (
            <div key={key} style={{ ...layout.options }}>
              {footerComp}
            </div>
          )
        case 'body':
          return (
            <div key={key} style={{ ...layout.options }}>
              {bodyComp}
            </div>
          )
        case 'leftSide':
          return (
            <div key={key} style={{ ...layout.options }}>
              {leftSideComp}
            </div>
          )
        case 'rightSide':
          return (
            <div key={key} style={{ ...layout.options }}>
              {rightSideComp}
            </div>
          )
        case 'breadcrumb':
          return (
            <div key={key} style={{ ...layout.options }}>
              {breadcrumbComp}
            </div>
          )
        case 'help':
          return (
            <div key={key} style={{ ...layout.options }}>
              {helpComp}
            </div>
          )
        case 'navigation':
          return (
            <div key={key} style={{ ...layout.options }}>
              {navigationComp}
            </div>
          )
        case 'subNavigation':
          return (
            <div key={key} style={{ ...layout.options }}>
              {subNavigationComp}
            </div>
          )
        case 'horizontal': {
          layout.elements?.forEach((l: LayoutBuilderElement, k: number) => {
            if (l !== undefined) components.push(_layoutBuilder(l, k))
          })
          return (
            <HorizontalLayout key={key} style={{ ...layout.options }}>
              {components}
            </HorizontalLayout>
          )
        }
        case 'vertical': {
          layout.elements?.forEach((l: LayoutBuilderElement, k: number) => {
            if (l !== undefined) components.push(_layoutBuilder(l, k))
          })
          return (
            <VerticalLayout key={key} style={{ ...layout.options }}>
              {components}
            </VerticalLayout>
          )
        }
      }
      return <></>
    },
    [
      bodyComp,
      breadcrumbComp,
      footerComp,
      headerComp,
      helpComp,
      leftSideComp,
      rightSideComp,
      navigationComp,
      subNavigationComp,
    ]
  )

  React.useEffect(() => {
    setFullLayout(_layoutBuilder(layoutConf ?? minimalLayout))
  }, [layoutConf, _layoutBuilder])

  const resetLayoutsHandler = (elements: LayoutElement[]) => {
    elements.forEach((e) => {
      console.log('reset ', e)
      switch (e) {
        case 'LEFT-SIDE':
          setLeftSideComp(undefined)
          break
        case 'RIGHT-SIDE':
          setRightSideComp(undefined)
          break

        case 'BREADCRUMB':
          setBreadcrumbComp(undefined)
          break
      }
    })
  }

  const layoutElementsHandler = (settings: Record<LayoutElement, React.ReactElement<any>>) => {
    for (let k in settings) {
      console.log('Settings: ', k)
      layoutHandler(k as keyof typeof settings, settings[k as keyof typeof settings])
    }
  }

  return (
    <LayoutContext.Provider
      value={{
        header: headerComp,
        breadcrumb: breadcrumbComp,
        footer: footerComp,
        body: bodyComp,
        leftSide: leftSideComp,
        rightSide: rightSideComp,
        resetLayouts: resetLayoutsHandler,
        setLayoutElement: layoutHandler,
        setLayoutElements: layoutElementsHandler,
      }}
    >
      <VerticalLayout style={{ height: '100%' }}>{fullLayout}</VerticalLayout>
    </LayoutContext.Provider>
  )
}

const minimalLayout: LayoutBuilderElement = {
  element: 'vertical',
  options: { gap: 10, width: '100vw', minHeight: '100vh', alignItems: 'stretch' },
  elements: [
    {
      element: 'vertical',
      options: { gap: 20 },
      elements: [
        {
          element: 'vertical',
          options: { gap: 0 },
          elements: [
            {
              element: 'header',
              options: {
                width: '100vw',
                minHeight: '50px',
                backgroundColor: '#dedede',
                alignItems: 'flexStart',
                background: '#005bbc',
                color: 'white',
              },
            },

            {
              element: 'breadcrumb',
              options: {
                width: '100vw',
                minHeight: '20px',
                paddingLeft: '20px',
                backgroundColor: '#dedede',
                color: 'black',
                fontWeight: 700,
                fontSize: '1.1rem',
              },
            },
          ],
        },

        {
          element: 'body',
          options: { display: 'flex', minWidth: '90vw', width: '90vw', alignSelf: 'center' },
        },
      ],
    },

    {
      element: 'footer',
      options: {
        minHeight: '10vh',
        display: 'flex',
        backgroundColor: '#ffd600',
        color: 'black',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  ],
}
