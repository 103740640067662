import punycode from 'punycode';

const CONF = {
    'MIN_LENGTH': 3,
    'MAX_LENGTH': 255,
    'MIN_TOKENS': 2,
    'MIN_TOKEN_LENGTH': 1,
    'MAX_TOKEN_LENGTH': 63,
    'TOKEN_REGEXP': /^[-a-z0-9]+$/,
    'TLD_REGEXP': /^[a-z][-a-z0-9]*$/
}

export interface IDomainValidatorResult {
    valid: boolean;
    error?: string;
    value?: string;
}

export class DomainValidator {

    static validate(domain: string): IDomainValidatorResult {

        if (!domain || 0 === domain.length) {
            return {"valid": true};
        }

        const length: number = domain.length;

        if (length < CONF.MIN_LENGTH) {
            return {"valid": false, "error": "domainNameTooShort"};
        }

        if (length > CONF.MAX_LENGTH) {
            return {"valid": false, "error": "domainNameTooLong"}
        }

        // Uniformato il comportamento del dns-check
        let punyEncodedDomain = punycode.toASCII(domain);


        let tokens = punyEncodedDomain.split(".");

        if (tokens.length < CONF.MIN_TOKENS) {
            return {"valid": false, "error": "domainBelowMinTokens"};
        }

        for (let index = 0; index < tokens.length; index++) {
            let token = tokens[index];

            if (token.length < CONF.MIN_TOKEN_LENGTH) {
                return {"valid": false, "error": "domainTokenTooShort", "value": token}
            }

            if (token.length > CONF.MAX_TOKEN_LENGTH) {
                return {"valid": false, "error": "domainTokenTooLong", "value": token}
            }

            if (token.indexOf("-") === 0) {
                return {"valid": false, "error": "domainTokenStartsWithDash", "value": token}
            }

            if (token.lastIndexOf("-") === token.length - 1) {
                return {"valid": false, "error": "domainTokenEndsWithDash", "value": token}
            }

            if (!CONF.TOKEN_REGEXP.test(token)) {
                return {"valid": false, "error": "domainTokenInvalid", "value": token};
            }
        }

        if (!CONF.TLD_REGEXP.test(tokens[tokens.length - 1])) {
            return {"valid": false, "error": "domainInvalidTLD"};
        }

        return {"valid": true};
    }
}

const IT_CONF = {
    'MIN_LENGTH': 6,
    'TLD': 'it'
}

export  class ItDomainValidator extends DomainValidator {

    static validate(domain: string, onlyIt:boolean = true): IDomainValidatorResult {
        let result = super.validate(domain);

        if (!result.valid) {
            return result;
        }

        if (domain.length < IT_CONF.MIN_LENGTH) {
            return {"valid": false, "error": "domainNameTooShort"};
        }

        if (onlyIt) {
            let tokens = domain.split(".");
            if (!(tokens[tokens.length - 1] === IT_CONF.TLD)) {
                return {"valid": false, "error": "domainInvalidTLD"};
            }
        }
        return {"valid": true};
    }
}

