import React from 'react'

import {Div, HorizontalLayout, PaperLayout, VerticalLayout} from "@nic/ui-comps";
import {RED} from "../configuration/index";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {useLocation} from "react-router-dom";
import {faCircleCheck} from "@fortawesome/free-regular-svg-icons";
import Utils from "../api/Utils";
import WarningInfo from "./WarningInfo";
import {PUBTEST_WARNING} from "../configuration";
import {useKeycloak} from "@react-keycloak/web";

interface RiepilogoFinaleI {}

const RiepilogoFinale: React.FC<RiepilogoFinaleI> = (props) => {

    let location= useLocation()

    const {search} = location;
    const params = new URLSearchParams(search)

    const _validatedData = params.get('res')

    const noValidation = () => {
        return (
            <VerticalLayout>
                <Div fontSize={'1.1rem'} fontWeight={'700'} >
                    <FontAwesomeIcon
                        style={{marginRight: '10px'}}
                        color={RED} icon={faExclamationTriangle}
                    />
                    Errore
                </Div>
                <Div>Operazione non permessa</Div>
            </VerticalLayout>
        )
    }


    const validated = (validatedData: any, tokenParsed: any) => {
        const val = atob(validatedData);
        const decoded = decodeURI(val)
        const jsonValue = JSON.parse(decoded)

        const configuration = jsonValue.configuration;
        const email = Utils.isPubTest(configuration) ? tokenParsed.email : jsonValue.data.pec;

        return (
            <HorizontalLayout style={{gap: 20}}>
                <FontAwesomeIcon size={'4x'} color={'green'} icon={faCircleCheck}/>
                <VerticalLayout style={{gap:10}}>
                    <Div fontSize={'1.2rem'} fontWeight={'700'}>Operazione conclusa con successo</Div>
                    <WarningInfo msg={PUBTEST_WARNING} rendered={Utils.isPubTest(configuration)} />
                    <Div>La validazione è stata effettuata e verrà inviata una mail all'indirizzo:

                    <span style={{fontWeight:700, paddingLeft: '10px', paddingRight: '10px'}}>{email}</span>
                        contenente il codice di validazione e i dati necessari per poter registrare nomi a dominio gov.it.</Div>

                    <Div>Nel messaggio sono stati inseriti anche i dati personali di colui che ha effettuato la procedura di validazione dell’amministrazione</Div>
                </VerticalLayout>
            </HorizontalLayout>
        )
    }


    const {keycloak} = useKeycloak();
    const {tokenParsed} = keycloak;


    let component = (_validatedData === undefined ? noValidation() : validated(_validatedData, tokenParsed));


    return (
        <PaperLayout asCard={true}>
            {component}
        </PaperLayout>
    )
}
export default RiepilogoFinale