import React from 'react';
import { Flex } from '@nic/ui-comps'

export interface ISpinner {
    message?: string;
    percentage?: number;
}

export const Spinner: React.FC<ISpinner> = (props) => {
    let percentValue: string = props.percentage ? String(props.percentage) : '20';
    percentValue += '%';

    return (
        <Flex direction="column" align="center" justify="center"  style={{textAlign: 'center', alignSelf: 'center'}}>
            <img alt={'Loading..'} style={{height: 'auto', width: percentValue, minWidth: '65px'}} src={'/images/spinner.svg'}/>
        </Flex>
    )
}
