import React, {useEffect} from 'react';

import {Avatar, Button, notification, Steps} from "antd";
import {CheckOutlined, LeftOutlined, RightOutlined} from '@ant-design/icons';

import './Wizard.css'
import {ValidateContext} from "../context/ValidateContext";

import {Map} from '../types/Map';
import {empty, fooT} from "../utils/utils";
import {IStepContext} from "../types";
import {FormError} from "./Form";
import {Div, Flex, MediaQuery, VerticalLayout} from "@nic/ui-comps"
import {StoreContext} from "../../../context/FormContext";

import Description from "../components/Description";


const Step = Steps.Step;

interface WizardStepInterface {
    title: string;
    description: string | React.ReactElement;
    component: React.ReactElement<any>;
    validate: (data: any, t: ((key: string) => string)) => Map<string>;
}

export type StepFunctionType<A,K> = (context: IStepContext<A,K>) => WizardStepInterface;

export interface ResponseError {
    field: string;
    value: string;
    message: string;
    status: string;
}

interface WizardPropsInterface<A,K> {
    steps: Array<StepFunctionType<A,K>>;
    onDone: (data: any)=>void;
    saving?:boolean;
    context: IStepContext<A,K>;
    errors?: Map<string>;
    data?: any;
    start?:number;
    useTranslation?:boolean;
    handleErrors?: (data: any) => Map<FormError>;
    handleCancel?: () => void;
    t?: (label: string) => string;
    className: string;
}

export enum ACTIONS {
    NEW='new',
    EDIT='edit',
    CANCEL='cancel'
}


type DefaultPropsInterface = WizardPropsInterface<ACTIONS,any>;

export const Wizard: React.FunctionComponent<DefaultPropsInterface> = (props) => {
    const  t =  props.t ?? fooT;

    const [step, setStep] = React.useState(props.start || 0);
    const [errorMap, setErrorMap] = React.useState({} as Map<string>);
    const store = React.useContext(StoreContext);
    // console.log('start: ', props.start);

    const currStep = (props.steps[step] as StepFunctionType<ACTIONS,any>)(props.context);

    const resetValidator = () => {
        setErrorMap({} as Map<string>);
    };

    const forceErrors = (errors: Map<string>): any => {
        setErrorMap(errors);
        return errors;
    };

    const handleValidate = (t:(key:string)=>string): any => {


        const errors = currStep.validate(store.getData(), t);

       // console.log('HandleValidate: ', store.getData(), errors)

        setErrorMap(errors);
        return errors;
    };

    useEffect(()=> {

        if (props.errors !== undefined) {
            setErrorMap(props.errors);
        }

    }, [props.errors]);

    useEffect(()=> {

        const stepValue = (props.start !== undefined ? props.start : 0);
        setStep(stepValue);

    }, [props.start]);


    const horizontalSteps = (nativeStep: boolean) => {
        let steps = <></>;

       if (nativeStep) {
           steps = (
               <Steps current={step} >
                   {props.steps.map((step: StepFunctionType<ACTIONS, any>, i:number) => {
                       const title = (props.useTranslation !== undefined && !props.useTranslation ? step(props.context).title : t(step(props.context).title));
                       //const description = (props.useTranslation !== undefined && !props.useTranslation ? step(props.context).description : t(step(props.context).description));
                       return (
                           <Step key={i} title={title}/>
                       );
                   })}
               </Steps>
           )
       } else {
           steps = (
               <Flex direction={'row'}>
                    <Avatar
                        style={{backgroundColor: '#1990ff'}}
                        size={{xs: 40, sm: 40, md: 40, lg: 64, xl: 80, xxl: 100}}>
                        {`${step+1}/${props.steps.length}`}
                    </Avatar>
                   <Div marginLeft={'10px'} fontSize={'1.2rem'}>{props.steps[step](props.context).title}</Div>
                </Flex>
           )
       }
        return (
            <div className={"steps-header"}>

                {steps}
                <div className="steps-content">
                    <VerticalLayout>
                        <Description description={currStep.description}/>
                        {currStep.component}
                    </VerticalLayout>
                </div>
                <Flex direction={"row"} justify={"space-between"} style={{marginTop: '20px'}}>
                    {step === 0 && (props.handleCancel !== undefined ? <Button size={'large'} className={props.className} onClick={()=> {props.handleCancel && props.handleCancel()}}>Cancella</Button> : <div></div>)}
                    {step > 0 && <Button
                        size={'large'}
                        className={props.className}
                        type={'primary'}
                        disabled={step === 0}
                        onClick={() => {
                            setStep(step - 1);
                        }}
                    >
                        <LeftOutlined/>
                        {t('Precedente')}
                    </Button>
                    }

                    {(step < props.steps.length - 1) &&
                    <Button
                        className={props.className}
                        type={'primary'}
                        size={'large'}
                        disabled={step === (props.steps.length - 1)}
                        onClick={() => {
                            resetValidator();
                           // console.log('Passo da qui: 1')
                            const errors = handleValidate(t);
                           // console.log('Errors: ', errors);
                            // console.log("Errors: ", errors, Object.keys(errors).length > 0);
                            let foundError = (Object.keys(errors).length > 0);
                            /*
                            for (let k in errors) {
                                foundError = true;
//                                let field = (props.useTranslation !== undefined && !props.useTranslation ?  k : t(`${k}.label`));
                               // notification.error({message: t('field.error', {field}), description: errors[k] });
                                //console.log("Error: ", errors[k])
                            }
                            */
                           // console.log('FoundError: ', foundError)

                            if (!foundError) {
                                setStep(step + 1);
                            }
                        }}
                    >
                        {t('Prossimo')}
                        <RightOutlined/>
                    </Button>
                    }
                    {
                        (step === props.steps.length - 1) &&
                        <Button
                            size={'large'}
                            className={props.className}
                            loading={props.saving}
                            style={{backgroundColor: '#008758', color: 'white', borderColor: '#008758'}}
                            onClick={() => {
                                const errors: Array<string> = handleValidate(t);
                                for (let k in errors) {
                                    notification.error({message: "Errore nel campo", description: errors[k] });
                                }


                                if (empty(errors)) {

                                    props.onDone(store.getData());
                                }
                            }}
                        >
                            {t('Fatto')}
                            <CheckOutlined/>
                        </Button>
                    }
                </Flex>

            </div>
        )
    }
/*
    const verticalSteps = () => {
        return (
            <VerticalLayout>
            <Flex direction={'row'} justify={'flex-start'} align={'flex-start'}>
                <Steps current={step} direction={'vertical'}>
                    {props.steps.map((step: StepFunctionType<ACTIONS, any>, i:number) => {
                        const title = (props.useTranslation !== undefined && !props.useTranslation ? step(props.context).title : t(step(props.context).title));
                        // const description = (props.useTranslation !== undefined && !props.useTranslation ? step(props.context).description : t(step(props.context).description));
                        return (
                            <Step key={i} title={title}/>
                        );
                    })}
                </Steps>
                <HorizontalLayout>
                <div className="steps-content">
                    <HorizontalLayout>
                        {currStep.description}
                        {currStep.component}
                    </HorizontalLayout>

                </div>
                <Flex direction={"row"} justify={"space-between"} style={{marginTop: '20px'}}>
                    {step === 0 && (props.handleCancel !== undefined ? <Button onClick={()=> {props.handleCancel && props.handleCancel()}}>Cancella</Button> : <div></div>)}
                    {step > 0 && <Button
                        type={'primary'}
                        disabled={step === 0}
                        onClick={() => {
                            setStep(step - 1);
                        }}
                    >
                        <LeftOutlined/>
                        {t('Precedente')}
                    </Button>
                    }

                    {(step < props.steps.length - 1) &&
                    <Button
                        type={'primary'}
                        disabled={step === (props.steps.length - 1)}
                        onClick={() => {
                            resetValidator();
                            const errors = handleValidate(t);
                            //console.log("Errors: ", errors);
                            let foundError = false;
                            for (let k in errors) {
                                foundError = true;
                                let field = (props.useTranslation !== undefined && !props.useTranslation ?  k : t(`${k}.label`));
                                notification.error({message: t('field.error', {field}), description: errors[k] });
                                //console.log("Error: ", errors[k])
                            }
                            if (!foundError) {
                                setStep(step + 1);
                            }
                        }}
                    >
                        {t('Successivo')}
                        <RightOutlined/>
                    </Button>
                    }
                    {
                        (step === props.steps.length - 1) &&
                        <Button
                            loading={props.saving}
                            style={{backgroundColor: '#12ad12', color: 'white', borderColor: '#12ad12'}}
                            onClick={() => {
                                const errors: Array<string> = handleValidate(t);
                                for (let k in errors) {
                                 //   notification.error({message: "Errore nel campo", description: errors[k] });
                                }


                                if (empty(errors)) {

                                    props.onDone(store.getData());
                                }
                            }}
                        >
                            {t('Fatto')}
                            <CheckOutlined/>
                        </Button>
                    }
                </Flex>
                </HorizontalLayout>
            </Flex>
            </VerticalLayout>
        )
    }
*/
    return (
        <ValidateContext.Provider
            value={{
                handleValidate: () => {handleValidate(t);},
                forceErrors: (errors:Map<string> ) => {forceErrors(errors);},
                errors: errorMap,
                resetValidation:()=> {resetValidator();}
            }}
        >
            <MediaQuery break={'xxl'}>
                {horizontalSteps(true)}
            </MediaQuery>
            <MediaQuery break={'xl'}>
                {horizontalSteps(true)}
            </MediaQuery>
            <MediaQuery break={'l'}>
                {horizontalSteps(true)}
            </MediaQuery>
            <MediaQuery break={'m'}>
                {horizontalSteps(true)}
            </MediaQuery>
            <MediaQuery break={'xs'}>
                {horizontalSteps(false)}
            </MediaQuery>
            <MediaQuery break={'s'}>
                {horizontalSteps(false)}
            </MediaQuery>

        </ValidateContext.Provider>
    )
};