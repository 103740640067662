import React from 'react'
import get from 'lodash/get'
import {StoreContext} from "../context/FormContext";
import AmministrazioneViewer from "./AmministrazioneViewer";


interface ViewAmministrazioneDataI {
    ammPath: string
}

const ViewAmministrazioneData: React.FC<ViewAmministrazioneDataI> = (props) => {
    const store = React.useContext(StoreContext)
    const amm = get(store.getData(), props.ammPath)
    return (
        <AmministrazioneViewer
            view={'minimal'}
            direction={'row'}
            amministrazione={amm}
        />
    )
}

export default ViewAmministrazioneData